@charset "UTF-8";

/******************************************** DEFAULT SLIDER ********************************************/

:root{
    
    
    --slider-color: #{$color-white};
    --slider-background: #{$color-primary};
    
    
    --slider-arrow-default: 0.75;
    --slider-arrow-hover: 1;
    --slider-arrow-disabled: 0.25;
    
    --slider-arrow-size: 30px;
    --slider-arrow-fontsize: 15px;
    --slider-arrow-bordersize: 0;
    --slider-arrow-shadow: none;
    
    --slider-arrow-prev: "\e910";
    --slider-arrow-next: "\e90d";
    
    --slider-dot-size: 10px;
    --slider-dot-background: transparent;
    --slider-dot-bordersize: 1px;
    
    --slider-dot-mode: relative;
    --slider-dot-vertical: 0; //0 for horizontal, 1 for vertical;
    --slider-dot-direction: row; // flex-direction;
    --slider-dot-justify: center; // justify-content;
    --slider-dot-align: center; // align-items;
}



.slider{
    
    &{
        position: relative; 
        transform: translate(0,0);
        overflow: hidden;
        
        /***** NORMAL *****/
        
        /* Arrows */
        
        //Position
        
        --slider-arrow-next-position: calc(50% - (var(--slider-arrow-size) / 2) ) calc(var(--slider-arrow-size) * -1.5) auto auto;
        --slider-arrow-prev-position: calc(50% - (var(--slider-arrow-size) / 2) ) auto auto calc(var(--slider-arrow-size) * -1.5);
        
        
        //Appearance
        
        --slider-arrow-color: var(--slider-color);
        --slider-arrow-background: var(--slider-background);
        
        --slider-arrow-bordercolor: var(--slider-arrow-color);
        --slider-arrow-radius: calc(var(--slider-arrow-size) / 2);
        
        
        /* Dots */
        
        //position
        --slider-dot-color: var(--slider-background);
        
        //Appearance   
        --slider-dot-radius: calc(var(--slider-dot-size) / 2);
        --slider-dot-size-active: calc(var(--slider-dot-size) * 2);
        --slider-dot-position: calc(var(--slider-dot-size) / 2) 0 0 0;
        
        
        
        /***** STATES *****/
        
        --slider-color-hover: var(--slider-color);
        --slider-background-hover: var(--slider-background);
        
        /* Arrows */
        
        --slider-arrow-color-hover: var(--slider-color-hover);
        
        --slider-arrow-bordercolor-hover: var(--slider-arrow-color-hover);
        --slider-arrow-background-hover: var(--slider-background-hover);
        
        /* Dots */
        
        --slider-dot-color-highlight: var(--slider-background-hover);
        
        --slider-dot-color-active: var(--slider-dot-color-highlight);
        --slider-dot-background-active: var(--slider-dot-color-highlight);
        --slider-dot-color-hover: var(--slider-dot-color-highlight);
        --slider-dot-background-hover: var(--slider-dot-color-highlight);
        
        
        cursor: grab;
        cursor: -webkit-grabbing;
        cursor:-moz-grabbing;
        
        
    }
    
    &-container{
        margin: 0 45px;
        transform: translate(0,0); //keep for the overflow of navigation
        & > *{
            overflow: hidden;
            transform: none;
        }
    }
    
    & img{
        height: 100%;
        //max-width: 100%;
        object-fit: cover;
    }
    
 /*************************************************************************************************/   
 
 
 
 
    
/******************************************** VARIANTS ********************************************/
    
    &--unlimited{
        // overflow: visible;
    }
    
    &--alt{
        --slider-color: #{$color-white};
        --slider-color-hover: #{$color-quinary};
        --slider-background: #{rgba($color-white,0.5)};
        --slider-arrow-fontsize: 23px;
        --slider-arrow-size: 50px;
        --slider-arrow-radius: 2px;
        --slider-arrow-bordersize: 1px;
        --slider-dot-color: var(--slider-color);
        --slider-dot-color-highlight: var(--slider-color-hover);
    }
    
    
    @mixin arrowsInside($vertical) {
        
        @if($vertical) {
            --slider-arrow-prev-position: auto 30px 30px auto;
            --slider-arrow-next-position: auto 30px calc(var(--slider-arrow-size) + 40px) auto;
        } @else {
            --slider-arrow-prev-position: calc(50% - var(--slider-arrow-size) / 2 ) auto auto 30px;
            --slider-arrow-next-position: calc(50% - var(--slider-arrow-size) / 2 ) 30px auto auto;
        }
    }
    
    @mixin dotsInside($vertical) {
        
        --slider-dot-mode: absolute;
        // --slider-dot-color: var(--slider-color);
        // --slider-dot-color-highlight: var(--slider-color-hover);
        
        @if($vertical) {
            --slider-dot-vertical: 1;
            --slider-dot-direction: column;
            --slider-dot-position: auto auto 0 var(--slider-dot-size);
        } @else {
            --slider-dot-position: auto auto var(--slider-dot-size) 0;
        }
    }
    
    //SLIDER WITH ARROWS AND PAGINATION INSIDE
    
    &--inside{
        @include arrowsInside(false);
        @include dotsInside(false);
        &--vertical{
            @include arrowsInside(true);
            @include dotsInside(true);
        }
    }
    
    //SLIDER WITH ARROWS INSIDE
    &--arrows{
        &--inside{
            @include arrowsInside(false);
            &--vertical{
                @include arrowsInside(true);
            }
        }
    }
    
    //SLIDER WITH PAGINATION INSIDE
    &--dots{
        &--inside{
            @include dotsInside(false);
            &--vertical{
                @include dotsInside(true);
            }
        }
        
    }
    
    
    

}
/**************************************************************************************************/












/****************** SLICK.JS *******************/

$slick: '.slick';

#{$slick} {
    &-list {
        .slick-loading & {
            // background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
        }
    }
    
    &-track{
        min-width: 100%;
        display: flex !important;
    }
    
    &-slide{
        display: inline-block;
        height: inherit !important;

        & > div {
            display: flex;
            height: 100%;
        }

        & img{
            height: auto;
        }

    }
    
    /* Arrows */

    &-arrow {
        position: fixed;
        display: block;
        height: var(--slider-arrow-size);
        width: var(--slider-arrow-size);
        cursor: pointer;
        background: var(--slider-arrow-background);
        color: var(--slider-arrow-color);
        padding: 0;
        border: var(--slider-arrow-bordersize) solid var(--slider-arrow-bordercolor);
        outline: none;
        box-shadow: var(--slider-arrow-shadow);
        border-radius: var(--slider-arrow-radius);
        font-size: 0;
        z-index: 10;
        transition: 0.5s, position 0s;
        opacity: var(--slider-arrow-default);

        &:hover {
            outline: none;
            background: var(--slider-arrow-background-hover);
            border-color: var(--slider-arrow-bordercolor-hover);
            color: var(--slider-arrow-color-hover);
            opacity: var(--slider-arrow-hover);
        }
        
        &::before {
            font-family: $icon;
            line-height: 1;
            font-size: var(--slider-arrow-fontsize);
            color: inherit;
            opacity: var(--slider-arrow-default);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
        }
    }

    &-prev {
        inset: var(--slider-arrow-prev-position);
        [dir="rtl"] & {
            inset: var(--slider-arrow-next-position);
        }
        &::before {
            content: var(--slider-arrow-prev);
            [dir="rtl"] & {
                content: var(--slider-arrow-next);
            }
        }
    }

    &-next {
        inset: var(--slider-arrow-next-position);
        [dir="rtl"] & {
            inset: var(--slider-arrow-prev-position);
        }
        &::before {
            content: var(--slider-arrow-next);
            [dir="rtl"] & {
                content: var(--slider-arrow-prev);
            }
        }
    }
    
    &-disabled:before {
        opacity: var(--slider-arrow-disabled) !important;
    }

    /* Dots */

    #{$prefix} &-dots {
        
        // position: absolute;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: var(--slider-dot-direction);
        align-items: var(--slider-dot-align);
        justify-content: var(--slider-dot-justify);
        width: auto;
        height: auto;
        min-width: calc( 100% * (1 - var(--slider-dot-vertical) ) );
        min-height: calc( 100% * var(--slider-dot-vertical) );
        max-width: 100%;
        max-height: 100%;
        position: var(--slider-dot-mode);
        left: 0;
        inset: var(--slider-dot-position);
        margin: 0;
        // padding: calc(20px * var(--slider-dot-vertical) ) calc(20px * (1 - var(--slider-dot-vertical) ) );
        padding: calc(20px * var(--slider-dot-vertical) ) calc(20px * (1 - var(--slider-dot-vertical) ) );
        text-align: center;


        
        li {
            
            position: relative;
            display: inline-block;
            cursor: pointer;
            text-align: center;
            transition: 0.3s;
            width: var(--slider-dot-size);
            height: var(--slider-dot-size);
            padding: 0;
            margin: calc(var(--slider-dot-size) / 2) calc(var(--slider-dot-size) / 2);
            
            button {
                display: inline-block;
                outline: none;
                border: var(--slider-dot-bordersize) solid var(--slider-dot-color);
                background: var(--slider-dot-background);
                display: block;
                height: 100%;
                width: 100%;
                line-height: 0px;
                font-size: 0px;
                cursor: pointer;
                border-radius: var(--slider-dot-radius);
                position: relative;
                z-index: 10;
                padding: 0;
                transition:background-color 0.3s;
                &:hover {
                    background: var(--slider-dot-background-hover) !important;
                    border-color: var(--slider-dot-color-hover) !important;
                    outline: none;
                }
            }
                        
            &#{$slick + '-active'} {
                height: calc( ( var(--slider-dot-size) * (1 - var(--slider-dot-vertical) ) ) + ( var(--slider-dot-size-active) * var(--slider-dot-vertical) ) );
                width: calc( ( var(--slider-dot-size-active) * (1 - var(--slider-dot-vertical) ) ) + ( var(--slider-dot-size) * var(--slider-dot-vertical) ) );
                button{
                    background: var(--slider-dot-background-active);
                    border-color: var(--slider-dot-color-active);
                }
            }
            
        }


    }
}



