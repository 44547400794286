.hero{
    
    min-height: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    position: relative;
    
    &--overlay{
        color: $color-white;
        &::before{
            content:'';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($color-black,0.25);
            z-index: 5;
        }
    }
    
    &-content{
        z-index: 10;
        position: relative;
    }
    
    &-img{
        border-radius: 5px;
        overflow: hidden;
    }
}