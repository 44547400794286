.mapbox {
    &-select {
        &-searchbar {
            position: relative;

            input {
                background: white;
                border: var(--input-border) solid var(--input-border-color);
                border-radius: 4px;
                height: var(--input-height);
                padding: 10px 63px 10px 20px;
                font-family: $fp;
                font-weight: 500;
                width: 100%;

                &::placeholder {
                    font-family: $fp;
                    opacity: 1;
                    color: #09090B;
                }
            }

            .mapboxgl {
                &-ctrl {
                    &-geocoder {
                        &--button {
                            border: 0;
                            background: white;
                            padding: 0;
                            position: absolute;
                            right: 40px;
                            top: 11px;
                            display: none;
                        }

                        &--icon {
                            &-search {
                                position: absolute;
                                right: 12px;
                                top: 10px;
                                width: 24px;
                                height: 24px;
                            }

                            &-loading {
                                display: none;
                            }
                        }
                    }
                }
            }

            .suggestion {
                &s {
                    padding: 8px 16px;
                    font-size: 12px;
                    font-weight: normal;
                    border: 1px solid #CBD5E1;
                    border-radius: 4px;
                    background: white;
                    margin-top: 8px;
                    cursor: pointer;

                    li {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    &gl {
        &-touch-pan, 
        &-scroll-zoom {
            &-blocker {
                z-index: 2;
            }
        }
    }
}
