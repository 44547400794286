.home {
    &-grid {
        &-image {
            height: 250px;
            object-fit: cover;

            @include bp(xs, sm) {
                height: 170px;
            }
        }
    }
}

.print, .order {
    display: none !important;
}