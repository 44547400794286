@use 'sass:math';

/*****************  PRODUCT GRID PAGER  *****************/


#{$prefix} .pager {

	&-content {
		display: flex;
		justify-content: center;
		align-items: center;
	}


	&-item {
		&s {
			display: flex;
			justify-content: center;
			align-items: stretch;
			border-left: none;
			font-size: 0;
			letter-spacing: -1px;
			line-height: 10px;
			white-space: nowrap;
			margin: 0;
			padding: 0;
			list-style: none none;
			font-weight: 400;
		}

		& {
            display: flex;
            align-items: center;
            margin: 0;
		}

		& + & {
			margin-left: 1px;
		}

    	&-label {
    		font-size: 20px;
    		font-weight: 700;
    		justify-content: center;
    		align-items: center;
        	color: $color-secondary;
        	display: flex;
        	align-items: center;
        	transition: $duration;
        	border: solid 1px $color-grey-light;
        	margin-bottom: 0;
            padding: 15px;
            text-decoration: none;
            
    		&:hover{
    			transition: 0s;
    			background: $color-primary;
    			color: $color-white;
                border-color: $color-primary;
    		}
            
            &--current {
                color: $color-white;
                background: $color-primary-darker;
                border-color: $color-primary-darker;
                pointer-events: none;
            }
    	}

        
		&-button{
			cursor: pointer;

			&-action {
				margin: 0 20 0 20;
				font-size: 20px;
				padding: 5px;
				&::before{
					content: unset;
				}
				&-background {
					background:none;
					border: none;
				}
			}
		}
	}
}




/*****************  BREADCRUMBS  *****************/


.breadcrumb {
	
	&-container{
		padding: 10px 10px;
		// margin: -20px -10px 20px -10px;
		// width: calc(100% + 20px);
		color: $color-main;
		background-size:cover;
		font-size: 12px;
		display: none;
		z-index: 1;
		position: relative;


		@include bp(md){
			// width: calc(100% + 80px);
			padding: 10px 0;
			display: block;
			// margin: -40px -40px 20px -40px;
		}
		
		@include bp(md,md){
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -10px;
	}

	&-item{
		line-height: 1;
		position: relative;
		display: none;

		@include bp(md){
			line-height: 19px;
			display: flex;
			margin-right: 4px;
			font-size: 16px;
		}

		&::first-letter{
			text-transform: uppercase;
		}

		&-link{
			position: relative;
			
			&::after{
				content:'';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 0;
				height: 1px;
				background: currentColor;
				transition: 0.5s;
			}
			
			&:hover{
				text-decoration: none;
				
				&::after{
					width: 100%;
				}
			}
		}

		&--current{
			font-weight: bold;
			color: $color-primary;
            pointer-events: none;
		}
		
		&:last-child{
			font-weight: bold;	
		}

		& + &{
			
			&::before{
				@include bp(md){
					font-size: 16px;
					font-weight: normal;
					content:'>';
					margin-right: 4px;
					position: relative;
					font-family: Arial;
				}
			}
		}
	}
}



/************  PRODUCT SWATCHES  **************/

:root{
    --swatch-width: 30px;
    --swatch-padding: 4px 8px;
    --swatch-margin: 10px;
    --swatch-text-size: 12px;
    --swatch-text-height: 24px;
    --swatch-color-height: calc(var(--swatch-text-height) / 1.2);
}

$swatch: '.swatch';
#{$prefix} #{$swatch}{
        
    &--big{
        --swatch-width: 50px;
        --swatch-padding: 6px 12px;
        --swatch-margin: 15px;
        --swatch-text-size: 16px;
        --swatch-text-height: 40px;
        --swatch-color-height: calc(var(--swatch-text-height) / 1.2);
    }
    
    &-attribute{
        
        &:not(:first-child){
            padding-top: calc(var(--swatch-margin) * 0.5);
        }
        
        &-option{
            
            &s{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin: 10px calc(var(--swatch-margin) * -1) calc(var(--swatch-margin) * -1) 0;
                
            }
        }
    }
    
    &-option{
        
        border: 1px solid $color-grey-light;
        background: $color-grey-lighter;
        height: 24px;
        min-height: var(--swatch-text-height);
        min-width: var(--swatch-width);
        max-width: 100%;
        position: relative;
        text-align: center;
        text-overflow: ellipsis;
        transition: 0.5s;
        outline: 1px solid transparent !important;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space:nowrap;
        height: auto;
        font-size: var(--swatch-text-size);
        line-height: 1.2;
        padding: var(--swatch-padding);
        margin: 0 var(--swatch-margin) var(--swatch-margin) 0;
        
        &:not(.disabled, #{$swatch}--disabled, .active, .selected):hover{
            outline: 1px solid $color-grey-light !important;
            border-color: transparent;
            color: $color-main;
            transition: 0s;
        }
        
        &.disabled{
            pointer-events: none;
            opacity: 0.4;
        }
        
        &.active,
        &.selected{
            transition: 0.5s;
            background: $color-primary;
            color: $color-white;
            outline: 1px solid $color-primary !important;
            border-color: transparent;
        }
        
        &.text,
        &--text{
        }
        
        &.color,
        &--color{
            height: var(--swatch-color-height);
            min-height: var(--swatch-color-height);
            padding: 0;
        }
    }
}


/*****************  RATING STARS  *****************/



#{$prefix} .rating{
	
	&-1::before{
		content: '\e90b';
	}
	
	&-2::before{
		content: '\e90b \e90b';
	}
	
	&-3::before{
		content: '\e90b \e90b \e90b';
	}
	
	&-4::before{
		content: '\e90b \e90b \e90b \e90b';
	}
	
	&-5::before{
		content: '\e90b \e90b \e90b \e90b \e90b';
	}
		
	
	&-result{
		
		& label{
			top: 0;
		}
		
		margin-left: 0;
		height: 30px;
		width: 87px;
		margin-right: 13px;
        position: relative;
        
        & span{
            overflow: hidden;
            top: 0;
            position: absolute;
        }
		
		
		&::before, & span::before{
			content: '\e90b \e90b \e90b \e90b \e90b';
		}
		
		&::before, & span::before, & label::before {
			font-size: 15px;
			height: auto;
			font-family: $icon;
			letter-spacing: 3px;
			left: 0;
			height: 30px;
			line-height: 30px;
			color: $color-primary;
		}
		
		&::before{
			content: '\e90e \e90e \e90e \e90e \e90e';
		}
		
		&--small{
			margin-right: 0;
			height: 22px;
			width: 65px;
			
			&::before, & span::before, & label::before {
				font-size: 11px;
				letter-spacing: 2px;
				height: 22px;
				line-height: 22px;
			}
		}
	}
	
}


/*****************  CATEGORY HEADER  *****************/


.category{
    
    &-header{
        
        //if overlay needed        
        &::before{
        }
        
        &-background{
            background-position: center top;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100%;
            z-index: -1;
        }
        
        &-content{
            position: relative;
        }
    }
    
}


/*****************  PRODUCT GRID FILTERS  *****************/



.filter{
	 &-title{
	}
	
    //remove filters button
	&-clear{
		margin-bottom: 10px;
		display: inline-block;
	}
	
    //factive filters
	&-item{
		&s{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-left: 0;
		}
		
		&{
			margin-right: 15px;
			padding: 8px;
			padding-right: 10px;
			border: 2px solid $color-primary;
			display: flex;
			align-items: center;
			
			&-name{
				text-transform: uppercase;
				&::after{
					content: ' : ';
					margin-left: 3px;
				}
			}
			
			&-value{
				display: flex;
				align-items: center;
				margin-right: 15px;
				
				& .price{
					font-size: 14px;
					padding: 0 2px;
					font-weight: normal;
				}
			}
			
			//remove filter
			&-remove{
				color: $color-primary;
				position: relative;
				margin-left: auto;
				
				&::before{
					transform:none;
					font-family: $icon;
					content:'\e912';
					height: auto;
					background: none;
					font-size: 9px;
				}
				
				&::after{
					display: none;
				}
			}
		}
	}
	
    // price slider filter
	&-slider{
		margin: 0 23px;
	}
	
    //filter list
	&-option{
		
		 &s{
			
			margin-top: 10px;
			
			&-title{
				font-size: 14px;
				color: $color-primary;
				padding: 12px 20px 12px 0;
				border: none;
				border-top : 1px solid $color-grey-light;
				margin: 0;
				position: relative;
				cursor: pointer;
				text-transform: uppercase;
				&::before{
					right: 1px;
					top: 12px;
					position: absolute;
					font-family: $icon;
					content:'\e90f';
				}
				
				&[aria-expanded=true]{
					&::before{
						content:'\e911';
					}
				}
			}
			
			&-content{
				border-top : 1px solid $color-grey-light;
				padding-top: 20px;
				padding-bottom: 0;
				margin-bottom: 20px;
			}
			
			&-item{
				&s{
					padding-left: 30px;
					margin-left: 0;
				}
				
			}
		
		}
		
		 &-item + &-item{
			margin-top: 13px;
		}
		
		 &-item{
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-bottom: 0;
			
			
			&-link{
				padding-left: 0;
				display: flex;
				align-items: center;
				font-weight: 500;
			}
			
			&-label{
				display: inline-block;
				margin-right: 5px;
			}
		}
	}
}


////////////////////////////////////////////////////////////
/************************  AMASTY  ************************/
////////////////////////////////////////////////////////////


/*****************  PRODUCT GRID FILTERS  *****************/

#{$prefix} .ui-slider{
    
    &{
        height: 5px;
        background: $color-grey-light;
        
        &::before,
        &::after{
            display: none;
        }
    }
    
    &-handle{
        top: 3px;
        transition: 0s;
        cursor: pointer;
        background: $color-primary;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        transform: translate(-10px, -11px);
    }
    
    &-range{
        height: 4px;
        background: $color-primary;
        position: relative;
        top: 0;
    }
    
    &-delimiter{
        opacity: 0;
        display: inline-block;
        width: 18px;
    }
    
    &-input{
        max-width: calc(50% - 9px);
    }
    
    &-prefix{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
    }
}
