
.box {

	&--border{
		border: 2px solid $color-grey-lighter;
	}

	&--shadow{
		box-shadow: $box-shadow;
	}

	&--fill{
		background: $color-grey-lighter;
	}

    &--neutral{
        background: transparent;
    }

	background: $color-white;
	border-radius: 8px;
	padding: var(--gap-box);
    --gap-box: var(--gap-mobile);
    --section-border:1px;

	@include bp(md){
        --gap-box: var(--gap-tablet);
	}

	@include bp(lg){
        --gap-box: var(--gap-desktop);
	}

	&-content{
		& + &{
			margin-top: 45px;
		}
	}

	--gap-mobile: 20px;
	--gap-tablet: 30px;
	--gap-desktop: 40px;

	&--small{
		--gap-mobile: 15px;
		--gap-tablet: 20px;
		--gap-desktop: 20px;
	}

	&--medium {
		--gap-mobile: 25px;
		--gap-tablet: 25px;
		--gap-desktop: 25px;
	}

	&-img{
		height: 165px !important;
		margin: calc( var(--gap-mobile) * -1) calc( var(--gap-mobile) * -1) 0 calc( var(--gap-mobile) * -1);
		width: calc( 100% + var(--gap-mobile) * 2);
		max-width: none;
		display: block;
		@include bp(md){
			margin: calc( var(--gap-tablet) * -1) calc( var(--gap-tablet) * -1) 0 calc( var(--gap-tablet) * -1);
			width: calc( 100% + var(--gap-tablet) * 2);
		}

		@include bp(lg){
			margin: calc( var(--gap-desktop) * -1) calc( var(--gap-desktop) * -1) 0 calc( var(--gap-desktop) * -1);
			width: calc( 100% + var(--gap-desktop) * 2);
		}
	}

	&-section{
		margin-left: calc(var(--gap-box) * -1);
		margin-right: calc(var(--gap-box) * -1);
		padding: var(--gap-box) var(--gap-box);
        box-sizing: content-box !important;
		display: flex;
		flex-direction: column;


        & + &{
			border-top: var(--section-border) solid $color-grey-light;
            position: relative;
            top: -var(--section-border);
        }

        &:first-child{
			margin-top: calc(var(--gap-box) * -1);
        }

        &:first-child[style*='max-height: 0px'] + &{
            border-top: 0;
        }

        &:last-child{
            margin-bottom: calc(var(--gap-box) * -1 - var(--section-border));
        }

	}

	&-text{
		margin-bottom: 25px;
		font-weight: normal;
	}

	&::before{
		font-size: 60px;
		line-height: 1;
		text-align: center;
		display: block;
		margin: 0 auto 25px;
	}

	&-col{

		&s{
			display: flex;
			flex-direction: column;
			padding: 0 !important;
			margin-bottom: calc(var(--gap-box) * -1);

			@include bp(md){
				flex-direction: row;
			}

		}

		padding: var(--gap-box);

		& + &{
			border-top: 1px solid $color-grey-light;

			@include bp(md){
				border-top: 0;
				border-left: 1px solid $color-grey-light;
			}

		}
	}
}
