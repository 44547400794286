$tab : '.tab';
$tabs : #{$tab}+'s-container' ;
$mtabs : #{$tabs}+'--mobile';
$ltabs : #{$tabs}+'--large';

@mixin tabs() {
		
	&s{
		
		width: 100%;
		
		&--gap{
			padding: 0 10px;
			margin-bottom: -45px;
			
			@include bp(md){
				margin-top: 0;			
			}
			
			&--mobile{
				margin-bottom: 30px;
				@include bp(md){
					margin: 0;
				}
			}
		}
		
		&-header{
			
			display: flex;
			flex-wrap : nowrap;
			min-width: 100%;
			flex-wrap : wrap;
			justify-content: center;
			
			
			@include bp(ml){
				width: auto;
				flex-wrap : nowrap;
				// justify-content: flex-start;
			}
			
			
			&-row{
				display: flex;
				justify-content: center;
			}
			
			
			&--small{
				// border-bottom: 4px solid $color-grey-lighter;
				// margin-bottom: 27px;
				align-items: center;
				@include bp(ml){
					white-space: nowrap;
				}
			}
			
			
			&--small #{$tab}s-header-item{
				
				font-size: 13px;
				// padding: 12px 8px 8px;
				padding: 8px 8px;
				background: none;
				margin: 0;
				// min-width: 50%;
				
				@include bp(md){
					padding: 8px 8px;
					// min-width: initial;		
				}
				
				@include bp(ml){
					padding: 18px 8px;
					font-size: 16px;
				}
				
				&.active,
				&:hover{
					// border-color: $color-primary;
					// color: $color-primary;
				}
			}
			
			&--medium{
				margin: 0 -7px;
			}
			
			$medium: #{&}--medium;
			
			#{$medium} &-item{
				margin: 0 7px;
				font-size: 16px;
				padding: 19px 10px;
				align-content: center;
				align-items: center;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
			}

			
			&-item{
				
				
				&::before{
					display: none;
				}
				padding: 15px 10px;
				width: 100%;
				flex: 1;
				cursor: pointer;
				transition: background $duration, border-color $duration;
				text-align: center;
				display: block;
				background: $color-white;
				font-size: 14px;
				border: 0px;
				position: relative;
				font-family: $fp;
				font-weight: 500;
				opacity: 0.6;
				transition: 0.5s;
				position: relative;
				margin: 0 5px;
				text-transform: uppercase;
				color: $color-grey-dark;
				
				&  > a {
					transition: 0s;
				}
				
				@include bp(md){
					width: auto;
					padding: 15px 20px;
				}
				
				@include bp(lg){
					padding: 15px 20px;
					// width: 100%; 
					font-size: 14px;
					margin: 0 10px;
				}
				
				@include bp(lg){
					margin: 0 10px;
				}
				
				
				&--icon{
					padding: 10px 5px 20px;
					margin: 0 6px;
					border-radius: 3px 3px 0 0;
					
					&::after{
						width: 40px;
					}
					
					&::before{
						font-size: 36px;
						display: block;
					}
				}
				
				
				&.active,
				&:hover{
					opacity: 1;
					color: $color-primary;
					transition: 0.5s;
				}
				
				&::after{
					content:'';
					position: absolute;
					height: 2px;
					width: 67px;
					bottom: 0;
					left: 50%;
					background: $color-primary;
					transform: translateX(-50%) scaleX(0);
					opacity: 0;
					transition: 0.75s;
					
					@include bp(ml){
						width: 100px;
					}
					
				}
				
				&.active::after{
					opacity: 1;
					transform: translateX(-50%);
				}
				
				&.active{
					color: $color-primary;
				}
				
				&--alt{
					
					font-weight: bold;
					font-size: 16px;
					border-radius: 4px 4px 0 0;
					letter-spacing: 1px;
					opacity: 1;
					color: $color-main;
					padding: 19px 10px;
					margin: 0;
					
					&::after{
						display: none;
					}
					
					&.active,
					&:hover{
						opacity: 1;
					}
					
					&.active{
						color: $color-white;
						background: $color-primary;
					}
					
					
					&.active:hover{
						// background: $color-primary-lighter;
					}
					
					&:not(.active):hover{
						background: $color-grey-lighter;
						color: $color-main;
						color: $color-primary;
					}
					
					&:last-child{
						margin-right: 0;
					}
				}
				
				
				
				
			}
			
		}
				
	}
	
	&{
		opacity: 0;
		max-height:0;
		pointer-events:none;
		
		& + #{$tab}{
			margin-top: 0;
		}
		
		// background:$color-white;
		// width: 100%;
		// overflow: hidden;
		
		&:not(&--active){
			padding: 0;
			position: absolute;
			overflow: visible;
		}
	}
	
	&-image{
		max-width: 100%;
		
		& img{
			max-width: 100%;
		}
	}
	
	&-button{
		width: 100%;
		
		@include bp(xs,sm){
			font-size: 11px;			
		}
		
		@include bp(md){
			width: auto;			
		}
	}
	
	&-title{
		font-size: 20px;
		font-weight: 700;
		margin: 20px auto;
	}
	
	&-description{
		font-size: 16px;
		font-weight: 700;
		margin: 20px auto;
		
		& a{
			color: $color-primary;
			@include transition($duration);
			&:hover{
				color: $color-primary-lighter;
			}
		}
	}
	
	&--active{
		position: static;
		opacity: 1;
		pointer-events:initial;
		max-height:none;
		
			
		// padding: 0 15px 15px;
		
		&:not([class*=col]){
			@include bp(md){
				// padding: 15px 25px;
			}
		
			@include bp(ml){
				// padding: 40px;
			}
		}
	}
	
	&--alone{
		padding-bottom: 0;
	}

	
	&--transparent{
		background:transparent;
	}

}


#{$tabs}-container{
	width: 100%;
}

// TABS ALL DEVICES
#{$tabs} #{$tab} {
	@include tabs;
}

// TABS ONLY MOBILE
#{$mtabs} #{$tab} {
	
	@include bp(xs,sm){
		@include tabs;
	}
	
	@include bp(md){
		&{
			opacity: 1;
			max-height:none;
			pointer-events:all;
			padding: 0;
		}
		
		&s-header{
			display: none;
		}
		
		& + #{$tab} {
			margin-top: 20px;
		}
	}
}

// TABS ONLY TABLET + DESKTOP
#{$ltabs} #{$tab} {
	
	@include bp(xs,sm){
		&s-header{
			display: none;
		}
		
		& + #{$tab} {
			margin-top: 20px;
		}

	}
	
	@include bp(md){
		@include tabs;
	}
}
