/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.ui-datepicker {
    display: none;
    z-index: 999999 !important;
    top: 0 !important;
    left: 0 !important;
    transform: translateY(-50%);
    max-width: 100% !important;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
    cursor: pointer;
}
.ui-datepicker .ui-datepicker-next-hover span, .ui-datepicker .ui-datepicker-prev-hover span {
    border-color: transparent #ff8400 transparent transparent;
}
.ui-datepicker .ui-datepicker-prev {
    left: 0;
}
.ui-datepicker .ui-datepicker-next {
    right: 0;
}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    display: block;
    font-size: 0px;
    border: 0;
    border-width: 0;
    border-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: $color-primary !important;
    color: $color-main !important;
    top: 0;
    left: 0;
    right: auto;
    margin: 0 !important;
    margin-top: 0;
    transform: none;

    &::before{
        color: $color-main;
        font-weight: 400;
        font-size: 6px;
        line-height: 36px;
        font-family: $icon;
        content:'\e925';
        transform: rotate(90deg);
    }

}
.ui-datepicker .ui-datepicker-next span {
    border-width: 0;
    border-color: transparent;
    right: 0;
    left: auto;


    &::before{
        transform: rotate(-90deg);
    }

    
}
.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    font-weight: 800;
    color: $color-main;
    font-size: 20px;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
}
.ui-datepicker td span, .ui-datepicker td a {
    display: block;
    padding: 0px !important;
    padding-bottom: 1px !important;
    text-align: center;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    font-size: 14px;
    font-weight: 500;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}
.ui-datepicker-rtl {
    direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}
.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}
.ui-timepicker-div dl {
    text-align: left;
}
.ui-timepicker-div dl dt {
    height: 25px;
    margin-bottom: -22px;
}
.ui-timepicker-div dl .ui_tpicker_time_label {
    margin-bottom: -25px;
}
.ui-timepicker-div dl dd {
    margin: 0 10px 10px 65px;
}
.ui-timepicker-div td {
    font-size: 90%;
}
.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}
.ui-datepicker {
    padding: 30px 20px 95px 20px;
    border: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: $color-white;
    border-radius: 8px;
    box-shadow: 0px 20px 25px -5px #0000001A, 0px 8px 10px -6px #0000001A;
    -webkit-box-shadow: 0px 20px 25px -5px #0000001A, 0px 8px 10px -6px #0000001A;
}

.ui-datepicker .ui-datepicker-header {
    background: transparent;
    border: none;
    padding-bottom: 75px;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
    width: auto;
    margin-right: 0;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
    width: auto;
}
.ui-datepicker .ui-datepicker-calendar td {
    border: 0;
    background: $color-grey-lighter;
    color: $color-main;
    padding: 8px 2px;
    text-align: center;
    border-radius: 8px;
    transition: color 0.3s, background 0.3s;
    font-weight: 500;
    cursor: pointer;

    &:hover{
        transition: color 0s;
    }

    &::after{
        content:attr(title);
        font-size: 10px;
        color: $color-grey;
        transition: 0.3s;
        white-space: nowrap;
    }


    &:hover::after{
        color: $color-main;
        transition: 0s;
    }

    &:hover{
        background: $color-primary;
    }
}



.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day {
    border: 0;
    background: $color-main;
    color: $color-white;

    &::after{
        color: inherit;
    }
}


.ui-datepicker .ui-datepicker-calendar td a {
    display: block;
    border-radius: 0;
}
.ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
    background: inherit;
}
.ui-datepicker .ui-datepicker-calendar {
    background: $color-grey-lighter;
    border: 0;
    border-collapse: separate;
    border-radius: 12px;
    border-spacing: 2px;
    table-layout: fixed;
    min-height: 283px;
}
.ui-datepicker .ui-datepicker-calendar th {
    background: $color-grey-lighter;
    color: $color-main;
    border: 0;
    padding: 15px 4px;
    text-align: center;
    text-transform: initial;
    font-weight: 500;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
    text-align: center;
    border: #cfcfcf 1px solid;
}
.ui-datepicker {
    width: auto;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
    background: $color-grey-lighter;
    color: $color-primary-darker;
}
.ui-datepicker .ui-datepicker-calendar .ui-state-active {
    background: $color-main;
    color: $color-white;
}
.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}
.ui-timepicker-div dl {
    text-align: left;
}
.ui-timepicker-div dl dd {
    margin: 0 0 10px 65px;
}
.ui-timepicker-div td {
    font-size: 90%;
}
.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}
.ui-slider {
    position: relative;
    text-align: left;
}
.ui-slider-horizontal .ui-slider-handle {
    margin-left: -5px;
}
.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    cursor: default;
}
.ui-slider-horizontal {
    height: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    background: #dadada;
}
.ui-slider-handle {
    height: 10px;
    width: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #f98b25;
    display: block;
    position: absolute;
}
.ui-timepicker-div {
    padding: 10px 0 5px 0;
}
.ui-datepicker .ui-datepicker-buttonpane {
    overflow: hidden;
    padding-top: 10px;
    border-top: #cfcfcf 1px solid;
}
.ui-datepicker .ui-datepicker-calendar .ui-state-disabled {
    background: $color-grey-lighter !important;
    color: $color-main !important;
    opacity: 0.1;
}
.ui-datepicker .ui-datepicker-calendar .ui-state-disabled span {
    color: inherit;
}


.ui-datepicker .ui-datepicker-btn {
    &--top,&--bottom{
        position: absolute !important;
        width: calc(100% - 40px);
        left: 20px;
    }

    &--top{
        top: 80px;
    }

    &--bottom{
        bottom: 20px;
    }
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
    position: absolute; /*must have*/
    z-index: -1; /*must have*/
    filter: mask(); /*must have*/
    top: -4px; /*must have*/
    left: -4px; /*must have*/
    width: 200px; /*must have*/
    height: 200px; /*must have*/
}

.ui-timepicker-div .ui_tpicker_unit_hide {
    display:none;
}

@media (max-width: 768px) {
    .ui-datepicker {
        padding: 15px 15px 95px 15px;
    }

    .ui-datepicker .ui-datepicker-btn--top, .ui-datepicker .ui-datepicker-btn--bottom {
        width: calc(100% - 30px);
        left: 15px;
    }

    .ui-datepicker .ui-datepicker-btn {    
        &--top{
            top: 65px;
        }
    }
}