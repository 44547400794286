$hero : '.hero-reinsurances';

:root{
    --reinsurances-mobile: none; 
    --reinsurances-border: 0px; 
    --reinsurance-secondary: -webkit-box; 
    --reinsurance-margin: 30px;
    --reinsurances-gap: 0px;
    
    @include bp(lg){
        --reinsurances-gap: 60px;
    }
}

.reinsurance{
	 &s{
        display: var(--reinsurances-mobile);
		align-items: start;
		justify-content: center;
		flex-wrap: wrap;
        border-top : var(--reinsurances-border) solid $color-grey-light;
        border-bottom : var(--reinsurances-border) solid $color-grey-light;
        padding: 0 0;
        line-height: 1.2;

        #{$hero} &{
            align-items: stretch;
            gap: 16px;
            margin-top: -30px;

            @include bp(lg){
                gap: 32px;
                flex-wrap: nowrap;
                width: calc(100% - 96px) !important;
            }
        }
        
        &-wrapper{
            --reinsurances-border: 0px;
            --reinsurances-gap: 0px; 
            --reinsurance-secondary: none; 
            --reinsurances-mobile: flex;
			background: rgba($color-black,0.8);
            padding-top: 25px;
			width: 100%;

            #{$hero} &{
                background: none;

                @include bp(lg) {
                    margin: 0 -40px;
                    width: calc(100% + 80px);
                }
            }
        }
        
        @include bp(md){
            display: flex;
            padding: 30px var(--reinsurances-gap) calc(30px - var(--reinsurance-margin));
        }
	}
	
	&{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		text-align: center;
		width: 100%;
        margin-bottom: var(--reinsurance-margin);
        padding: 0 60px;
        font-size: 16px;
		color: $color-white;

        #{$hero} &{
			background: rgba($color-black,0.8);
            justify-content: start;
            padding: 24px 16px !important;
            margin-bottom: 0;
            flex-shrink: 0;
            
            @include bp(xs, sm){
                justify-content: center;
                padding: 8px !important;
                width: calc(50% - 8px);
            }
            
            @include bp(md, ml){
                justify-content: center;
                padding: 16px !important;
                width: calc(50% - 16px);
            }
        }

		@include bp(md){
            padding: 0 20px;
			width: 50%;

            &:first-child {
                padding-left: 0;
            }
    
            &:last-child {
                padding-right: 0;
            }
		}

		@include bp(lg){
			width: 25%;
			padding: 0 40px;
        }
		
		&::before{
			font-size: 35px;
			color: $color-primary;
			line-height: 1;
			margin-bottom: 8px;

            @include bp(md){
                margin-bottom: 10px;
            }
		}
        
        & > *{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 0;
        }

		& > :first-child {
			font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            margin-bottom: 5px;

            @include bp(md) {
                line-height: 28px;
                margin-bottom: 0;
            }

            @include bp(lg) {
                white-space: nowrap;
            }
		}

        & > :last-child {
            display: block;
			font-size: 14px;
			line-height: 20px;
            max-width: 168px;
        }
	}
    
    
    &--small{
        
         &{
            
            display: flex;
            align-items: center;
            line-height: 1;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            
            @include bp(lg){
                justify-content: center;
                margin-bottom: 0;
                margin: 0 40px;
                width: auto;
            }
            
            &::after{
                line-height: 1;
                font-size: 32px;
                margin-right: 15px;
                color: $color-primary;
            }
        }
        
          & + &{
            margin-top: 10px;
            @include bp(lg){
                margin-top: 0;
            }
        }
    }

}