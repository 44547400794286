
@mixin breakpoint ($args...) {

	$hasMinValue: false;
	$hasMaxValue: false;
	$min: nth($args, 1);
	$max: false;
	$loop-index : 0;

	@if (length($args) > 1) {
		$max: nth($args, 2);
	}

	@each $breakpoint in $breakpoints {
		$loop-index : $loop-index + 1;
		@if ($min != 0 and $min == nth($breakpoint, 1)) {
			$min: nth($breakpoint, 2);
			$min: '(min-width: #{$min})';
			$hasMinValue: true;
		}
		@if ($max != false and $max == nth($breakpoint, 1) and $max != nth($breakpoints, length($breakpoints))) {
			$max: nth(nth($breakpoints, $loop-index +1), 2);
			$max: $max - 1;
			$max: '(max-width: #{$max})';
			$hasMaxValue: true;
		}
	}

	$media: false;
	@if ($hasMinValue == true and $hasMaxValue == false) {
		$media: $min;
	}
	@if ($hasMinValue == false and $hasMaxValue == true) {
		$media: $max;
	}
	@if ($hasMinValue == true and $hasMaxValue == true) {
		$media: '#{$min} and #{$max}';
	}

	@if ($media != false) {
		@media #{$media} { @content; }
	} @else {
		@content;
	}

}


// Shortcut
@mixin bp ($args...) {
	@include breakpoint($args...) {
		@content;
	};
}