:root{
    --table-border-color: #{$color-grey-lighter};
    --table-border: 0px;
    --table-head-bg: #{$color-grey-darker};
    --table-head-color: #{$color-white};
    --table-cell-bg: #{$color-white};
    --table-cell-color: #{$color-main};
    --table-cell-padding: 8px 15px;
    --table-cell-border: var(--table-border);
    
}

#{$prefix} .tabler{
	
    
    
    //////// BASE //////
    
	color: $color-black;
	
	@include bp(ml){
    	border: var(--table-border) solid var(--table-border-color);
	}
	width: 100%;
	
	@include bp(xs,md){
		display: block;			
	}
    

    //////// CONTENT //////
	
	& .price{
		font-size: inherit;
		font-weight: normal;
	}
	
	thead {
		@include bp(xs,md){
			display: none;			
		}
	}
    
    tbody {
        @include bp(xs,md){
            
            & + tbody{
                margin-top: 20px;
            }
            
            td{
                &::before{
                    font-weight: 700;
                    content:attr(data-th);
                    margin-right: 15px;
                    text-align: right;
                }           
            }
            
            th{
                display: none;
            }
        }
    }
	
	tbody,tfoot{
	
		@include bp(xs,md){
			display: block;			
		}
	}
    
    tfoot{
        @include bp(xs,md){
            tr{
                display: flex;
                margin-top: calc(var(--table-border) * -1) !important;
                
                & > *{
                    width: 50%;
                    text-align: center;
                }
            }
        }
        
        @include bp(ml){
            th{
                text-align: right;
                --table-head-bg: var(--table-border-color);
                --table-head-color: var(--table-cell-color);

            }
        }
    }
	
	
	tr{
	
		@include bp(xs,md){
			display: block;			
            border: 1px solid $color-grey-medium;
			
			& + tr{
				margin-top: 20px;
			}
		}
        
        @include bp(ml){
            border-bottom: 1px solid $color-grey-medium;
        }
        
        &[data-tr]::before{
            @include bp(xs,md){
                font-weight: 700;
                content:attr(data-tr);
                background: var(--table-head-bg);
                color: var(--table-head-color);
                padding: var(--table-cell-padding);
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
        }           

		
		
	}
	
	td,th{
		
		padding: var(--table-cell-padding);
		text-align: center;
		vertical-align: middle;
		border-bottom: var(--table-cell-border) solid var(--table-border-color);
        @include bp(ml){
            border: var(--table-cell-border) solid var(--table-border-color);
        }
		
		:last-child > td{
			border-bottom: 0;
		}
	
		@include bp(xs,md){
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-align: left;			

            &.name {
                flex-direction: column;
                align-items: flex-start;

                .item-options {
                    margin-top: 8px;
                    text-align: left;
                }
            }
		}

        &.qty {
            li {
                margin: 0;
            }
        }
	}
    
    td{
        color: var(--table-cell-color);
        background: var(--table-cell-bg);
    }
    
    th{
        color: var(--table-head-color);
        background: var(--table-head-bg);
        font-weight: 500;
    }
    
    
    
    
    
    //////// VARIANTS //////
    
    &--border{
        --table-border: 1px;
        --table-cell-border: 0px;
        @include bp(ml){
        }
    }
    
    
    &--lined{
        tr{
            
            @include bp(xs,md){
                --table-cell-border: 1px;
            }
            
            @include bp(ml){
                --table-border: 1px;
            }
        }
    }
    
    &--bordered{
        --table-border: 1px;
        --table-cell-border: 1px;
        @include bp(ml){
        }
    }
    
    &--striped{
        tbody{
            tr:nth-child(even){
                --table-cell-bg: var(--table-border-color);
            }
        }
    }
    
    &--extrems{
        tbody, thead{
            
            @include bp(ml){
                
                td, th{
                    &:first-child{
                        text-align: left;
                    }
                    &:last-child{
                        text-align: right;
                    }
                }
                    
            }
        }
    }
    
    
    
    &--big{
        --table-cell-padding: 20px;
    }
    
    
    &--cased{
        tbody{
            @include bp(xs,md){
                --table-cell-border: 1px;
                td{
                    display: flex;
                    margin-top: calc(var(--table-border) * -1);
                    text-align: center;
                    padding: 0;
                    
                    & > *,
                    &::before{
                        width: 50%;
                        text-align: center;
                        padding: var(--table-cell-padding);
                    }                        
                        
                
                    &::before{
                        background: var(--table-border-color);
                        margin: 0;
                    }
                }
            }
        }
    }


    &--recap{
        --table-cell-bg: transparent;
        --table-head-bg: transparent;
        --table-head-color: var(--table-cell-color);
        --table-border-color: transparent;
        tbody{
            td::before{
                display: none;
            }
            
            tr {
                
                display: flex;
                justify-content: space-between;
                
                & + tr{
                    margin-top: 0;
                }
                
                &:nth-last-child(2){
                    --table-border-color: #{$color-grey-light};
                }
                
                &:last-child{
                    --table-cell-padding: 20px;
                    font-size: 20px 15px;
                    font-weight: bold;
                }
            }
            
            th{
                display: flex;
                flex-direction: column;
                align-items:flex-start;
                width: 100%;
                
            }
            
        }
    }
    
    
	
}

