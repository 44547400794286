$percentage: 40%;

:root {
    --alert-background: #{lighten($color-success, $percentage)};
    --alert-color: #{$color-success};
    --alert-progress-color: currentColor;
}


#{$prefix} .message {

    &s:not(.page.messages) {
        position: fixed;
        top: 20px;
        left: 20px;
        width: 100%;
        z-index: 2000;
        display: flex;
        flex-direction: column;
    }

    &s{
        pointer-events: none;
    }

    & {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
        min-width: 250px;
        max-width: 600px;
        position: relative;
        margin-bottom: 10px;
        transform: translateX(-100%);
        overflow: hidden;
        padding: 10px;
        pointer-events: none;
        color: var(--alert-color);
        background: var(--alert-background);
        &:not(.closed){
            animation: messageShow 8s ease-out forwards;
        }

        &.closed{
            animation: messageClose 0.5s ease-in-out forwards;
        }
    }

    & > :first-child {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.3;

        &::before {
            font-family: $icon;
            left: -20px;
            top: 0;
            margin: 0 10px 0 0;
            width: auto;
            overflow: unset;
            position: unset;
            font-size: 18px;
        }
    }

    &--error,
    &.error {
        --alert-background: #{lighten($color-error, $percentage)};
        --alert-color: #{$color-error};

        & > :first-child::before {
            content: "\e90d";
        }
    }

    &--success,
    &.success {
        --alert-background: #d8e7d6;
        --alert-color: #{$color-black};

        & > :first-child::before {
            content: "\e90d";
        }
    }

    &--info, &--warning,
    &.info, &.warning {
        --alert-background: #{$color-white};
        --alert-color: #{$color-primary};

        & > :first-child::before {
            content: "\e90d";
        }
    }

    &-close {
        margin-left: 30px;
        padding: 5px;
        cursor: pointer;

        &::before {
            font-size: 14px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 3px;
        border-radius: 2px;
        width: 100%;
        animation: barAnimation 8s ease forwards 0s;
        background: var(--alert-progress-color);
    }
}

@keyframes messageShow {
    0% {
        transform: translateX(-240px);
        margin-bottom: 10px;
        padding: 10px;
        height: auto;
        pointer-events: all;
    }
    3% {
        transform: translateX(0);
    }
    96% {
        margin-bottom: 10px;
        padding: 10px;
        height: auto;
        pointer-events: all;
        transform: translateX(0);
    }
    100% {
        margin-bottom: 0;
        height: 0;
        padding: 0 10px;
        overflow: hidden;
        transform: translateX(0);
        pointer-events: none;
    }
}

@keyframes messageClose {
    0% {
        margin-bottom: 10px;
        padding: 10px;
        height: auto;
        pointer-events: all;
        transform: translateX(0);
    }
    100% {
        margin-bottom: 0;
        height: 0;
        padding: 0 10px;
        overflow: hidden;
        transform: translateX(0);
        pointer-events: none;
    }
}

@keyframes barAnimation {
    from { width: 100%  };
    to   { width: 0 };
}

.account {
    .message {
        animation: none !important;
        transform: none !important;
        pointer-events: inherit !important;
    }
}
