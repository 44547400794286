
[data-sidebar-toggle]{
	cursor: pointer;
}

.sidebar{
	
	z-index: 200;
	position: fixed;
	height: 100%;
	top: 0;
	right: 0;
	width: 100%;
	pointer-events:none;
	
	&--active{
		pointer-events:all;
	}
	
	&-container{
		position: absolute;
		height: 100%;
		top: 0;
		right: 0;
		transform: translateX(100%);
		width: calc(100% - 20px);
		background: $color-white;
		transition: 1s 0.05s cubic-bezier(0.82, 0.02, 0.27, 1);
		overflow: auto;
		overflow: overlay;
		padding-bottom: 30px;
		
		@include bp(md){
			width: 700px;
		}
		
		&--background{
			transition: 1s cubic-bezier(0.82, 0.02, 0.27, 1);
			background: $color-grey-lighter;
		}
	}
	
	&--active &-container{
		transform: none;
	}
	
	
	&-overlay{
		content:'';
		background: $color-grey-darker;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		transition: 0.75s;
		cursor: pointer;
	}
	
	
	&--active &-overlay{
		transform: none;
		opacity: 0.6;
	}
	
	
	&-header{
		--border-color: #{$color-grey-light};
		width: 100%;
		color: $color-grey-darker;
		display: flex;
		border-bottom:1px solid var(--border-color);
		align-items: center;
		justify-content: flex-start;
		background-size: cover;
		z-index: 10;
		
		&--overlay{
			--border-color: #{rgba($color-white,0.4)};
			position: relative;
			color: $color-white;
			position: absolute;
			top: 0;
			left: 0;
			&::before{
				opacity: 0.8;
				z-index: 0;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				content:'';
				background-image: linear-gradient(180deg, rgba($color-black, 60%), rgba($color-black, 0) 100%);
			}
		}
		
		&-content{
			z-index: 15;
			padding: 15px 20px;
			width: 100%;
			@include bp(md){
			}
		}
		
		&-pretitle{
			font-size: 14px;
			display: flex;
			width: 100%;
			align-items: center;
			font-weight: bold;
			
			@include bp(md){
				font-size: 18px;
			}
			
			&::before{
				font-size: 24px; 
				margin-right: 13px;
				// line-height: 1;
			}
		}
		
		&-title{
			font-family: $fs;
			font-size: 14px;
			line-height: 20px;
			
			@include bp(md){
				font-size: 26px;
				line-height: 30px;
			}
			
			&::before{
				content: attr(data-text);
			}
		}
		
		&-pretitle + &-title{
			margin-top: 3px;
		}
	}
	
	&-close{
		border-right: 1px solid var(--border-color);
		cursor: pointer;
		transition: 0.75s 0.1s;
		padding: 25px 15px;
		font-size: 8px;
		text-transform: uppercase;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		text-align: center;
		width: 55px;
		min-width: 55px;
		align-self: stretch;
		z-index: 15;
		
		@include bp(md){
			width: 70px;
			min-width: 70px;
		}
		
		&::before{
			width: 100%;
			font-size: 21px;
		}
	}	
	
	
	&-overlay{
		content:'';
		background: $color-grey-darker;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		transition: 0.75s;
		cursor: pointer;
	}
	
	
	.active > &-overlay{
		transform: none;
		opacity: 0.6;
	}

	
	&-summary{
		padding: 21px 25px;
		font-size: 12px;
		background: $color-white;
		border-radius: 3px 3px 0 0; 
		margin: -45px auto 30px;
		width: calc(100% - 40px);
		position: relative;
		z-index: 5;
		
		@include bp(md){
			width: calc(100% - 90px);
			margin: -65px auto 30px;
		}
		
		&-item{
			padding-top: 4px;
			padding-bottom: 4px;
			&::before{
				content: '•';
				margin-right: 4px;
			}
		}
	}
		
	&-content{
		padding: 10px; 
		@include bp(md){
			// padding: 0 40px 40px; 
		}
	}
	
	&-tab{
		padding-left: 0;
		padding-right: 0;
	}
	
	&-gallery{
		position: relative;
		overflow: hidden;
		
		& .slick-track{
			min-width: 100%;
		}
		
		& .slick-slide{
			display: inline-block;
		}
		
	}
	
	&-title{
		
		display: flex;
		align-items: center;
		width: 100%;
		font-size: 16px;
		font-family: $fs;
		margin-top: 21px;
		
		@include bp(md){
			margin-bottom: 18px;
			margin-top: 34px;
			margin-left: -16px;
			font-size: 22px;
		}
		
		&::before{
			
			margin-right: 20px;
			font-size: 37px;
			line-height: 1;
		}
	}
	
	&-title + &-form{
		margin-top: -14px;
		
		@include bp(md){
			margin-top: -18px;
            padding: 20px 40px;
		}
	}
	
	&-subtitle{
		font-size: 16px;
		margin-bottom: 20px;
		font-family: $fs;
		
		@include bp(md){
			font-size: 22px;
			margin-bottom: 24px;
		}
	}
	
	&-text{
		line-height: 22px;
		font-size: 11px;
		
		@include bp(md){
			font-size: 14px;
			line-height: 28px;
		}
		
		&--emphasis{
			padding-left: 16px;
			& p{
				
				position: relative;
				&::before{
					position: absolute;
					font-family: $icon;
					content:'\e906';
					font-size: 14px;
					top: 0;
					left: -20px;
				}
			}
		}
		
		& p + p{
			margin-top: 20px;
		}
	}
	
	&-choose{
		
		border-top: 1px solid $color-grey-light;
		border-bottom: 1px solid $color-grey-light;
		display: flex;
		align-items: center;
		margin: 0 -20px 40px;
		@include bp(md){
			margin: 0 -40px 40px;
		}
				
		&-price{
			padding: 15px 15px;
			text-align: center;
			align-items: center;
			justify-content: center;
			display: flex;
			flex-wrap: wrap;
			border-right: 1px solid $color-grey-light;
			white-space: nowrap;
			
			@include bp(md){
				padding: 30px 34px;
			}
			
			&-amount{
				width: 100%;
				font-size: 24px;
				font-weight: 700;
				@include bp(md){
					font-size: 28px;
				}
			}
		}	
		
		&-dropdown{
			border: 1px solid $color-grey;
			border-radius: 3px;
			position: relative;
		}
		
		&-content{
			padding: 30px 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-left: -1px;
			border-left: 1px solid $color-grey-light;
			flex-wrap: wrap;
			width: 100%;
			
			@include bp(md){
				width: auto;
				flex-wrap: nowrap;
			}
			
			&-item{
				width: 100%;
				@include bp(md){
					width: auto;
				}
				& + &{
					padding-top: 10px;
					@include bp(md){
						padding-top: 0;
						padding-left: 20px;
					}
				}
			}
		}
		
	}
}