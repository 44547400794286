
@include font-face(
    $fp,
    'fonts/Roboto-Light',
    300,
    normal,
    woff woff2
);

@include font-face(
    $fp,
    'fonts/Roboto-Regular',
    400,
    normal,
    woff woff2
);

@include font-face(
    $fp,
    'fonts/Roboto-Medium',
    500,
    normal,
    woff woff2
);

@include font-face(
    $fp,
    'fonts/Roboto-Bold',
    700,
    normal, 
    woff woff2
);

@include font-face(
    $fp,
    'fonts/Roboto-Black',
    900,
    normal,
    woff woff2
);

 
 /* FONTICON ICOMOON, USE IF NEEDED */
 

@include font-face(
    $icon,
    'fonts/warner',
    400,
    normal,
    woff woff2
);

