.customer{
    &-box{
        background: #ffffff;
        border-radius: 4px;
        padding: var(--gap-box);
        --gap-box: var(--gap-mobile);
        --section-border: 1px;
        --gap-mobile: 20px;
        --gap-tablet: 30px;
        --gap-desktop: 40px;
        &-content{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }
        &-title{
            font-size: 20px;
            font-weight: 900;
            line-height: 28px;
            text-align: left;
            margin-bottom: 16px;
            display: inline-block;
        }
        &--border{
            border: 1px solid $color-grey-light;
            @include bp(md){
                border: none;
            }
        }
    }
    &-account{
        &-login{
            .row.g-0{
                margin-right: -1px;
            }
        }
    }
    &-block{
        &-collapsible{
            &-nav{
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 5;
                    @include bp(md){
                        position: static;
                        background-color: $color-white;
                    }

                &-title{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;

                    &::after{
                        position: absolute;
                        right: 34px;
                        top: 24px;
                        font-size: 8px;
                        transition: all .3s ease;

                        @include bp(md){
                            display: none;
                        }
                    }

                    &.active {
                        &::after {
                            top: 22px;
                            transform: rotate(180deg);
                        }
                    }

                    @include bp(md){
                        font-size: 20px;
                        font-weight: 800;
                        line-height: 28px;
                    }

                    @include bp(md, ml) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    &-nav{
        &-item{
            padding : 12px 24px 12px 24px;
            gap: 8px;
            font-size: 16px;
            font-weight: 900;
            line-height: 24px;
            color : $color-grey-darker;

            @include bp(md, ml) {
                padding: 8px 0px 8px 0;
            }

            &::before{
                color: $color-primary;
                margin-right: 10px;

                @include bp(md, ml) {
                    margin-right: 8px !important;
                }
            }
            & a{
                &:visited
                {
                    text-decoration: none;
                    color : inherit;
                }
            }
        }
    }
    &-page{
        &-main{
            padding-top: 70px;
            position: relative;
            @include bp(md){
                padding-top: 0;
                position: static;
            }

            .table-order-items {
                
            }
        }
    }
    &-account{
        &-edit{
            .input-box-icon.password {
                position: relative !important;
                float: right;
                top: 24px;
            }
        }
    }
}

.account {
    .page-title {
        margin-top: 0 !important;

        &-wrapper {
            padding: 0;
        }
    }

    &-password {
        &-toggle {
            display: inline-block;
            height: 40px;
            font-size: 0;
            cursor: pointer;
            position: absolute;
            right: 0px;
            top: 38px;
            width: 40px;

            &::before {
                font-size: 20px;
                color: $color-grey;
            }
        }

        &-wrapper {
            position: relative;
        }
    }

    .tabs-container {
        .nav.item.current {
            @extend .active;
        }

        .nav.item {
            @extend .tabs-header-item;
        }

        a {
            color: inherit;
        }
    }
}

.form {
    &-address {
        &-edit {
            .message + .message {
                display: none !important;
            }
        }
    }
}