.cms {

	line-height: 1.5;
    
    & > :last-child{
        margin-bottom: 0;
    }

	& {
		margin-bottom: 20px;
	}
    
    &:last-child{
        margin-bottom: 0;
    }

	& a {
		@include link;
		@include link-underline;
		font-weight: normal;

		&:hover {
			color: $color-primary !important;
		}
		
		&:visited {
			color: inherit !important;
		}
	}

	& img {
		max-width: 100%;
		margin: 15px auto;
        display: block;
        margin-bottom: 30px;
    
		@include bp(xs, sm) {
			height: auto !important;
		}

		@include bp(md) {
            margin: 30px auto;
		}
    }

	& table {
		width: 100% !important;
	}

	& td {
		width: auto !important;
	}
    
    & h1,
    & h2,
    & h3,
    & h4,
    & h5{
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    
    $titles : ['h1','h2','h3','h4','h5'];
    
    & h1{
        @include page-title($primary,$titles);
    }

	& h2 {
		@include page-title($tributary,$titles);
	}
    
    & h3{
        @include page-title($tertiary,$titles);
    }
    
    & h4{
        @include page-title($quaternary,$titles);
    }
    
    & h5{
        @include page-title($quinary,$titles);
    }


	& p {
        @include text-size($tertiary);
		margin-bottom: 14px;
		font-weight: normal;
		letter-spacing: normal;
		//text-align: justify;
		line-height: 1.5;
	}

	& ol {
		list-style-type: decimal;
		list-style-position: inside;
        @include text-size($primary);
        font-weight: 500;
	}

	& ul {
        // font-weight: 500;
		list-style-type: disc;
		list-style-position: inside;
		// padding-left: 30px;
		font-family: $fp;
		@include text-size($primary);
		// line-height: 1.57;
		text-align: left;
		margin-bottom: 30px;
		& li {
			list-style: none;
			&:before {
				content: '-';
				padding-right: 3px;
			}
			& a {
				color: $color-secondary;
				text-decoration: revert;
			&:hover,
			&:active {
				color: $color-primary-darker;

				}
			}
		}
	}

	& strong {
		font-weight: 600;
	}
    
	& em {
		font-weight: 400;
		font-style: italic;
	}

	@include bp(xs, sm) {

		& p,
		// & div,
		& h1,
		& h2,
		& h3,
		& p,
		& img,
		& ul {
			width: 100% !important;
			display: block;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	& iframe {
		max-width: 100%;
		width: 100%;
		min-height: 75vh;
		border-radius: 10px;
		overflow: hidden;
	}
}

img{
	image-rendering: -webkit-optimize-contrast;
}

[data-content-type='row']{
	max-width : none !important;
}

.imger{
	display: flex;
	&-item{
		width: 100%;
		height: 216px;
		display: flex;
		@include bp(md){
			height: 300px;
		}
		@include bp(lg){
			height: 400px;
		}

	}
}
