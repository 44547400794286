$vignette: '.vignette';

 #{$vignette} {
    
    &-box{
        
        #{$prefix} .ammenu-content-block & {
            padding: 0 10px;
        }
        
        #{$prefix} & {
            width: 100%;
            flex: 1 1 auto;
            position: relative;
            // padding-right: 10px;
            // padding-bottom: 10px;
            min-width:0;
            transition: opacity 0.6s, transform 0.6s;
            
            .slick-slide &{
                width: 100%;
                padding-bottom: 0;
            }
            
            &--slide{
                padding-left: 10px;
                padding-right: 10px;    
                padding-bottom: 40px;           
                @include bp(md){
                    padding-left: 14px;
                    padding-right: 14px;
                }
            }
                        
            
        }
        
    }
    
    #{$prefix} &{
        
        & {
            position: relative;
            cursor: pointer;
            transition: opacity 0.75s, transform 0.75s;
            transform: perspective(1200px);
            width: 100%;
            max-width:100%;
            background: $color-white;
            border: 1px solid $color-grey-light;
            border-radius: 4px;
            padding: 45px 5px 5px;
            display: flex;
            align-items: flex-start;
            cursor: pointer;
            flex-wrap: wrap;
            align-content: flex-start;
            margin: 0;
            border: 0;
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 rgba(50,51,53,0.2);
            transition: box-shadow 0.5s;
            height: 100%;
            
            @include bp(md){
                padding: 20px 20px 10px;
                border-radius: 10px;
            }
            
            &:hover{
                box-shadow: 0 2px 10px 0 rgba(50,51,53,0.5);
            }
            

            @include bp(md){
                min-height: min-content;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: initial;
            }


            @include bp(xl) {
                // width: 218px;
            }
        }

        &--loading {
            pointer-events: none;
        }

        &s-list &--loading{
            pointer-events:initial;
        }

        // for positioning potential tags
        &-tags{
            position: absolute;
            top: 10px;
            left: 10px;
            width: 100%;
        }
        
        // for custom vignette image
        &-img{
            
            &-container{
                position: relative;
            }
            
            display: block;
            margin: 0 auto;
            width: 130px;
            height: 130px;
            margin-bottom: 10px;
            object-fit: cover;
            transition: opacity 0.5s, transform 0.5s;
            
            @include bp(md){
                width: 140px;
                height: 140px;
            }
            // for custom second vignette image on hover
            &--hover{
                opacity: 0;
                pointer-events: none;
                position: absolute;
                left: 50%;
                top: 45px;
                transform: translateX(-20%);
                
                @include bp(md){
                    top: 20px;
                }
            }
        }
        
        &:hover #{$vignette}-img{
            
            &{
                // transform: translateX(-50%);
                opacity: 0; 
            }
            
            &--alone{
                opacity: 1;
            }
            
            &--hover{
                opacity: 1;
                transform: translateX(-50%);
            }   
        
        }
        
        &-details{
            text-align: center;
        }
        
        &-brand{
            margin-top: 5px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            min-height:22px;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
        }

        &-promo {
            min-width: 100%;
            flex-wrap: wrap;
        }


        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            box-shadow: none;
            
            &-details{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                flex: 1 1 auto;
            }
        }

        
        &-rating{
            display: flex;
            align-items: center;
            height: 22px;
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
            margin-top: 20px;
            margin-bottom: 25px;
        }
        
        //display out of stock overlay
        &-nostock{
            background: rgba($color-grey-darker,0.5);
            z-index: 10;
            position: absolute;
            left: 50%;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            transform: none;
            opacity: 1;
            font-size: 14px;
            text-transform: uppercase;
            transform: translateX(-50%) !important;
            line-height: 1;
            max-width: 100%;
            
            &-content{
                background: rgba($color-grey-darker,0.5);
                border: 2px solid $color-white;
                font-weight: bold;
                font-family: $fs;
                color: $color-white;
                padding: 12px;
            }
        }
        
        &-price {
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 20px;
            margin: 5px 0 10px;
            font-weight: bold;
            
            & .price{
                font-weight: bold;
                line-height: 22px;
                font-size: 20px;
                
                &-label{
                    display: block;
                    font-weight: 400;
                    color: $color-grey-light;
                    display: block;
                    margin-bottom: 2px;
                }
            }
            
            & .old-price{
                
                & *{
                    display: block;
                }
                
                &{
                    order:-1;
                    line-height: 1;
                    margin: 0 0 5px;
                    color: $color-grey-light;
                    text-decoration: line-through;
                }
                
                & .price{
                    margin: 0;
                    text-decoration: line-through;
                    font-size: 0.7em !important;
                    line-height: 1;
                    color: $color-grey-light;
                    &-label{
                        display: none;
                    }
                }
            
            }
            & > &{
                display: none !important;
            }
        }

        &-action{
            
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 8px;
            
            &:first-child{
                margin-top: 0;
            }


            &s{
                position: relative !important;
                margin: auto 0 0 0 !important;
                display: flex !important;
                width: 100% !important;
                height: auto !important;
                clip: initial !important;
                border: 0 !important;
                box-shadow: none !important;
                padding: 15px 0 0 0 !important;
                background: transparent !important;
                                
                justify-content: center;
                align-items: center;
                flex-direction: column;
                
                &::before{
                    display: none !important;
                }
                
                &-content{
                    margin: 0 !important;
                }
            }
        }
        
        &-icon{
            &::before{
                font-family: $icon;
                width: auto;
                font-size: 25px;
                line-height: 1;
            }
        }
    }
    
    #{$prefix} &s{
        
        & > *{
            margin-top: var(--bs-gutter-y);
        }

        //for amasty slider in megamenu
        &-slider{
            
            display: flex;
            flex-wrap: wrap;
            opacity: 0;
            transform: translateY(25px);
            transition: opacity 1s, transform 1s;
            @include bp(md){
                padding-bottom: 20px;
            }
            
            &.slick-initialized{
                opacity: 1;
                transform: none;
            }
            
            .slick-list{
                overflow: visible;
                @include bp(md){
                    overflow: hidden;
                }
            }
            
            .slick-prev,
            .slick-next{
                margin-top: -40px;
                background: $color-white !important;
                &::before{
                    color: $color-primary !important    ;
                }
            }
            
            .slick-prev{
                left: 0;
                @include bp(xl){
                    left: -70px;
                }
            }
            
            .slick-next{
                right: 0;
                @include bp(xl){
                    right: -70px;
                }
            }

        }
    }


}


#{$prefix} .rating{
    &-result{
        
        height: auto;
        
        & > span::before,
        &::before{
            font-family: $icon;
            content:"\e90b" "\e90b" "\e90b" "\e90b" "\e90b";
            color: $color-grey-light;
        }
        
        & > span::before{
            color: $color-quaternary;
        }
    }
}
