.page-footer{
	padding-bottom: 0;
	margin-top: 0;
}

.bugs{
	display: none !important;
}

.logo--footer {
	img {
		max-width: 100%;
		margin: auto !important;

		@include bp(md, ml) {
			margin: 0 !important;
		}
	}
}

.footer {
	&-container {
		background: rgba($color-black,0.8);
		margin-top: 30px;
		padding: 40px 0;

		.link {
			font-weight: 400 !important;
		}

		@include bp(xs, sm) {
			text-align: center;
		}

		p a {
			&:visited, & {
				color: $color-white;
				text-decoration: underline;
			}
		}

		.pagebuilder-column {
			strong {
				font-weight: 900 !important;
			}

			[data-content-type="text"] {
				strong:first-child {
					padding-bottom: 15px;
					display: inline-block;
				}
			}
	
			&{
				padding: 0 60px;
				
				@include bp(ml) {
					padding: 0 24px;
				}
				
				@include bp(lg){
					padding: 0 20px;
				}
		
				@include bp(md,ml){
					width: 50% !important;
				}
		
				@include bp(xs,ml){
					margin-bottom: 10px;
				}
			}
		
			&-line{
				flex-wrap: wrap !important;
				width: calc(100% + 40px) !important;
				margin: 0 -20px;
			}
		}

		@include bp(xs, sm) {
			.pagebuilder-column {
				padding: 0 20px;
			}
		}

		@include bp(lg) {
			.pagebuilder-column {
				width: fit-content !important;
				max-width: 300px;
	
				&-line {
					justify-content: space-between;
				}
	
				&:nth-child(3) {
					padding-right: 65px;
				}
			}
		}
	}

    &-wrapper{
        overflow: hidden;
		background: url(../images/footer-bg.webp);
		background-position: top center;
		background-size: cover;

		@include bp(xs,md) {
			&, & *{
				justify-content: center !important;
			}
		}
    }

	&-col {

		position: relative;
        --toggle-size: 13px;
		color: $color-white;



		@include bp(xs, sm) {
			padding: 15px 0;

			&::before {
				//content: "";
				bottom: 0;
				left: 50%;
				background: $color-primary;
				transform: translateX(-50%);
				position: absolute;
				width: 100vw;
				height: 1px;
			}

		}
        &-content {


            &.active{
                padding-top: 15px;
            }

            @include bp(md){
                padding-top: 15px;
            }
        }

        &-section{
    		& + & {
    			margin-top: 20px;
                @include bp(md){
                    margin-top: 30px;
                }
    		}
        }

	}

    &-link{
        margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
    }

	&-social {
		font-size: 26px;
		line-height: 1;
        padding-right: 20px;
		padding-bottom: 7px;

		@include bp(xs, md) {
			padding-right: 0;
			padding-left: 13px;
			margin-bottom: 0;
			padding-bottom: 0;

			.link::before {
				margin-right: 13px !important;
			}
		}

		& em::before{
			font-size: 22px !important;
		}

		&s {
			display: flex;
            flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
            margin-right: -25px;
            margin-bottom: -7px;
			padding-top: 15px;

			@include bp(ml) {
				padding-top: 15px;
				justify-content: flex-start;
			}

			@include bp(lg) {
				justify-content: center;
			}

			@include bp(xs, md) {
				margin-right: 0;
			}
		}
	}

    &-payment {
        padding-left: 20px;

        &-image {
            height: 25px;
        }
    }

    &-text{
        display: flex;
        line-height: 1.2;

        & + &{
            margin-top: 10px;
        }

        #{$prefix} & [class*="icon-"] {
            &::after {
                font-size: 1.1em;
            }
        }

		a:visited {
			color: inherit;
		}
    }

    &-schedule{
        line-height: 1.85;
    }

	&-legals {
		background: rgba($color-black,0.8);
        text-align: left !important;

		&-content{

			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			padding-bottom : 25px;
			flex-direction: column;

			@include bp(md) {
				flex-direction: row;
				padding-bottom: 45px;
			}

			.link {
				font-weight: 400 !important;
			}
		}

		&-item {
			color: $color-white;
			text-align: center;

			@include bp(md) {
				width: auto;
			}
		}

		&-link {
			white-space: nowrap;
            font-size: 12px;

			&:hover {
				text-decoration: none;
			}

			& + & {
				padding-left: 4px;
				margin-left: 8px;
				position: relative;
			}

		}
	}
}
