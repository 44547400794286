

@mixin icon {
	font-family: $icon;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	//line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
	&::before, &[class$="-after"]::after, &[class*="-after "]::after {
		@include icon;
	}
}

$icons: (

	(facebook, '\e908'),
	(twitter, '\e90a'),
	(instagram, '\e909'),
	(tiktok, '\e900'),
	(youtube, '\e90b'),

	(chevron-up, '\e925'),
	(chevron-down, '\e925'),
	(chevron-right, '\e925'),
	(chevron-left, '\e925'),
	(arrow-left, '\e907'),
	(arrow-right, '\e907'),
	(dropdown, '\e907'),

	(calendar, '\e905'),
	(cart, '\e903'),
	(phone, '\e90c'),
	(user, '\e902'),
	(users, '\e904'),
	(time, '\e906'),
	(info, '\e90d'),
	(ok, '\e90f'),
	(call, '\e913'),
	(warner, '\e901'),
	(check, '\e91d'),
	(close, '\e923'),
	(ticket, '\e911'),
	(ts, '\e912'),

	(closed, '\e919'),
	(open, '\e919'),
	(lock, '\e917'),
	(list, '\e918'),
	(help, '\e91a'),
	(pin, '\e91b'),
	(logout, '\e91c'),
	(copy, '\e91e'),
	(locate, '\e91f'),
	(wc, '\e920'),
	(atm, '\e921'),
	(eat, '\e922'),
	(baby, '\e914'),
	(cancel, '\e910'),
	(dressing, '\e915'),
	(secure, '\e924'),
	(invalid, '\e916'),

);

.icon {
	@each $icon in $icons {
		&-#{nth($icon, 1)} {
			&::before, &-after::after {
				content: nth($icon, 2) !important;
			}
		}
	}

	&--small{
		&::before, &::after{
			font-size: 0.7em;
		}
	}
}
