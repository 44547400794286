
/////// SECTIONS /////// 

.section {
    --section-gap: 50px;
    position: relative;
    clear: both;
    margin: var(--section-gap) 0;
    
    @include bp(md){
        --section-gap: 70px;
    }
    
    &--first{
        margin-top: 0;
    }

    &--last{
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: 0;
    }
    
    @mixin section-fill($bg){
        margin: 0;
        padding: var(--section-gap) 0;
        &,&::before,&::after{
            background: $bg;
        }
    }
    
    &--fill{
        @include section-fill($color-grey-lighter);

    }
    
    &--white{
        @include section-fill($color-white);
    }
    
    &--primary{
        @include section-fill($color-primary);
    }

    
    &--secondary{
        @include section-fill($color-secondary);
    }

    
    &--tertiary{
        @include section-fill($color-tertiary);
    }

    
    &--quaternary{
        @include section-fill($color-quaternary);
    }

    
    &--quinary{
        @include section-fill($color-quinary);
    }
    
    &s > &:nth-child(2n){
        @include section-fill($color-grey-lighter);
        background: $color-grey-lighter;
    }
    
    &--skew{
        --section-gap: 3%;
        padding-bottom: calc(var(--section-gap) * 0.9);
        & > *{
            position: relative;
            z-index: 70;
        }
        
        &::before,
        &::after{
            content: '';
            width: 100%;
            display: block;
            position: absolute;
            padding-top: 6%;
            // z-index: 5;
        }
        
        &::before{
            transform-origin: top left;
            transform: skewY(-3deg);
            top: 0;
        }
        
        &::after{
            transform-origin: top left;
            transform: translateY(100%) skewY(-3deg);
            bottom: 0;
        }
        
        &--top{
            z-index: 90;
            &::after{
                display: none;
            }
        }
        
        & + &{
            margin-top: 5.9%;
        }
    }
    
    &--skew + &:not(&--skew){
        margin-top: calc(var(--section-gap) * 2);
    }

}
