
.minicart{
	
	 &{
		border-radius: 20px;
		background-color: $color-white;
	 	box-shadow: 0 2px 10px 0 rgba(50,51,53,0.5);
	  	right: -24px;
	  	margin-top: 25px;
	  	padding: 20px;
	  	width: 360px;
	  	
	  	@include bp(lg){
		  	right: -36px;
	  	}
	  
		&-header{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-bottom: 10px;
			border-bottom: 1px solid $color-grey-light;
			
			&-count{
				font-size: 16px;
				font-weight: bold;
				margin: 0;
			}
		}

		&-btn{
			display: inline-block;
			margin-left: auto;
		}  		

		&::before{
			right: 28px;
			top: -19px;
			border: 10px solid;
			border-color: transparent transparent $color-white transparent;
			
			@include bp(lg){
				right: 40px;
			}
		}

		&::after{
			display: none;
		}
	}
	
	&-empty{
		font-weight: 500;
		text-align: center;
		display: block;
		padding: 10px 0;
	}
	
	 &-widgets{
		margin: 0;
	}

	&-bar{
		width: 100%;
		margin-top: 40px;
		@include bp(md){
			margin-top: 0;
		}
	}

	&-top{
		position: relative;

		&-illu{
			width: 100%;
			object-fit: cover;
			display: block;
		}
	}
	
	&-item{
		 &{
			position: relative;
			border-top: 1px solid $color-grey-light;
			padding: 20px 0;
			display: flex;
			margin-bottom: 0;
		}
		
		&:first-child{
			border-top: none;
		}
		
		&:last-child{
			border-bottom: 1px solid $color-grey-light;
		}
		
		&s{
			 &-wrapper{
				max-height: 380px;
				overflow: auto;
				padding: 0 20px;
				border: 0;
				
				@include bp(md){
					max-height: 380px;
				}
				
				@include bp(ml){
					max-height: 200px;
				}
				
				@include bp(xl){
					max-height: 380px;
				}
				
				&--big{
					max-height: 380px;
					padding-top: 10px;
				}
			}
		}
		
		&-img,
		& img{
			width: 55px;
			height: 55px;
			object-fit: cover;
		}
		
		&-name{
			font-size: 14px;
			font-weight: 500;
		}
		
		&-brand{
			font-size: 10px;
			font-family: $fs;
			font-weight: bold;
			text-transform: uppercase;
			color: $color-primary;
		}
		
		&-attr{
			display: block;
			font-weight: 500;
			font-size: 8px;
			padding-right: 80px;
		}
		
		&-price{
			font-size: 17px;
			position: absolute;
			bottom: 22px;
			right: 0;
			
			&--gap{
				bottom: 9px;
			}
		}
		
		 &-details{
			padding-left: 15px;
		}
		
		 &-remove{
			&::before{
				font-family: $icon;
				font-size: 14px;
				padding: 0 10px;
				line-height: 1;
			}
		}		
		
		 &-update{
			display: block !important;
			position: absolute;
			top: -30vh;
			opacity: 0;
		}		
	}

	 &-subtotal{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: bold;
		font-size: 16px;
		margin-top: 30px;
		margin-bottom: 30px;
		
		.price{
			font-size: 25px;
		}
	}

	&-toggle{

		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		margin-top: 10px;
		padding: 10px 25px 10px;
		background: $color-white;
		cursor: pointer;
		margin: 0 auto;
		position: relative;
		transition: 0.2s 0.4s;

		&::before{
			background: $color-grey-lighter;
			height: 2px;
			width: calc(100% - 20px);
			position: absolute;
			top: 0;
			left: 10px;
			content:'';
		}


		&::after{
			font-weight: normal;
			font-family: $icon;
			font-size: 15px;
			content:'\e904';
			margin-left: 8px;
			transition: 0.3s;
		}

		&.active{
			padding-bottom: 0;
			transition: 0s;
			&::after{
				transform: rotate(180deg);
			}
		}

		@include bp(md){
			display: none;
		}
	}

	&-container{

		overflow: hidden;
		max-height: 0;
		transition: 0.5s;
		margin-bottom: 20px;

		&:not(.open){
			margin-top: 0 !important;
		}

		&.open{
			max-height: none;
		}

		@include bp(md){
			margin-bottom: 20px;
		}


		@include bp(md){
			max-height: none !important;
		}
	}

	&-content{
		background: $color-white;
		padding: 10px;
	}

	&-header{
		&-title{
			font-size: 16px;
			color: $color-black;
			font-family: $fs;
			// margin-bottom: 17px;
			@include bp(md){
				margin-bottom: -3px;
			}
		}
	}

	&-section{
		border-top: 2px solid $color-grey-light;
		padding: 20px 10px;

		&-header{
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			white-space: nowrap;
			width: 100%;
			margin-bottom: 10px;

			@include bp(md,md){
				flex-wrap: wrap;
				justify-content: flex-end;
			}

			&-title{
				font-size: 16px;
				line-height: 19px;
				font-family: $fs;
				padding-right: 10px;
				white-space: initial;

				@include bp(md,md){
					margin-right: auto;
				}
			}
		}
	}

	&-block{
		padding: 28px 20px;
		background: $color-white;
	}

	&-totals{
		background: $color-grey-lighter;
	}

	&-point{
		&s{

		}

		&{
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&::before{
				font-size: 16px;
				margin-right: 12px;
			}
		}

		& + &{
			margin-top: 10px;
		}
	}

	&-grandtotal{
		margin-bottom: 7px;
	}

	&-price{

		&-content{
			display: flex;
			align-items: flex-end;
			position: relative;

			&::before{
				position: absolute;
				background: currentColor;
				content:'';
				width: 100%;
				height: 1px;
				bottom: 5px;
			}

			&--small{
				font-size: 12px;
				margin-top: 14px;

				&:last-child{
					margin-bottom: 30px;
				}

				:last-child > &{
					margin-bottom: 0;
				}

				overflow: hidden;
				max-height: 0;
				transition: 0.5s;

				&:not(.open){
					margin-top: 0 !important;
					margin-bottom: 0 !important;
				}

				&.open{
					max-height: none;
				}

			}

			&--big{
				font-family: $fs;
				font-size: 19px;
			}
		}

		&--detail{
			overflow: hidden;
			max-height: 0;
			transition: 0.5s;

			&:not(.open){
				margin-top: 0 !important;
			}

			&.open{
				max-height: none;
			}
		}

		& + &{
			margin-top: 15px;
		}

		&-label{
			width: 100%;
			position: relative;
			text-shadow:
				0px 0px 0px $color-white,
				1px 0px 0px $color-white,
				1px 0px 0px $color-white,
				2px 0px 0px $color-white,
				3px 0px 0px $color-white,
				4px 0px 0px $color-white,
				5px 0px 0px $color-white,
				6px 0px 0px $color-white,
				7px 0px 0px $color-white,
				8px 0px 0px $color-white,
				9px 0px 0px $color-white,
				10px 0px 0px $color-white,
				11px 0px 0px $color-white,
				-1px 0px 0px $color-white,
				-1px 0px 0px $color-white,
				-2px 0px 0px $color-white,
				-3px 0px 0px $color-white,
				-4px 0px 0px $color-white,
				-5px 0px 0px $color-white,
				-6px 0px 0px $color-white,
				-7px 0px 0px $color-white,
				-8px 0px 0px $color-white,
				-9px 0px 0px $color-white,
				-10px 0px 0px $color-white,
				-11px 0px 0px $color-white,
			;

			&--alt{
				line-height: 1;
				text-shadow:
					0px 0px 0px $color-grey-light,
					1px 0px 0px $color-grey-light,
					1px 0px 0px $color-grey-light,
					2px 0px 0px $color-grey-light,
					3px 0px 0px $color-grey-light,
					4px 0px 0px $color-grey-light,
					5px 0px 0px $color-grey-light,
					6px 0px 0px $color-grey-light,
					7px 0px 0px $color-grey-light,
					8px 0px 0px $color-grey-light,
					9px 0px 0px $color-grey-light,
					10px 0px 0px $color-grey-light,
					11px 0px 0px $color-grey-light,
					-1px 0px 0px $color-grey-light,
					-1px 0px 0px $color-grey-light,
					-2px 0px 0px $color-grey-light,
					-3px 0px 0px $color-grey-light,
					-4px 0px 0px $color-grey-light,
					-5px 0px 0px $color-grey-light,
					-6px 0px 0px $color-grey-light,
					-7px 0px 0px $color-grey-light,
					-8px 0px 0px $color-grey-light,
					-9px 0px 0px $color-grey-light,
					-10px 0px 0px $color-grey-light,
					-11px 0px 0px $color-grey-light,
				;
			}

			&--important{
				font-weight: bold;
				margin-top: 5px;
				display: inline-block;
			}

			&--small{
				display: inline-block;
				margin-top: 2px;
				font-size: 14px;
			}
		}

		&-amount{
			font-family: $fp;
			position: relative;
			font-weight: 700;
			padding-left: 9px;
			white-space: nowrap;
			background: $color-white;

			&--alt{
				background: $color-grey-lighter;
			}
		}

		&-toggle{
			display: inline-block;
			transition: 0.5s;

			&.active{
				margin-bottom: 15px;
			}
		}
	}
}
