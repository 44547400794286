.popup {
	position: fixed;
	width: 100vw;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 200;
	pointer-events:none;
    --popup-shadow: none;

		@include bp(lg) {
		height: 100vh;
	}
    
    &--success{
        --popup-shadow: 1px 1px 20px 3px #{rgba($color-success,0.62)};
    }
    
    &--error{
        --popup-shadow: 1px 1px 20px 3px #{rgba($color-error,0.62)};
    }

	&--active {
		pointer-events: all;

		.popup {
			
			&-container {
				opacity: 1;
				visibility: visible;
				transform: translate(50%,-50%) scale(1);
			}

			&-overlay{
				opacity: 0.6;
			}

			&-content-left img {
				transform: scale(1);
			}


			&-step.active{
				opacity: 1;
				animation: slideIn 0.5s forwards;
				-webkit-animation: slideIn 0.5s forwards;
            }

			@keyframes slideIn {
				0% {
					transform: translateX(100%);
				}
				100% { 
					// opacity: 1;
					transform: translateX(0%); 
				}
			}
		}

	}

	&-overlay{
		background: $color-grey-darker;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.2s ease;
		cursor: pointer;
	}

	&-container {
		position: fixed;
		height: fit-content;
		width: 100%;
		max-width: 400px;
		top: 50%; 
		right: 50%;
		transform: translate(50%,-50%) scale(1.25);
		opacity: 0;
		visibility: hidden;
		background: #fff;
		transition: all 0.3s ease, box-shadow 0.5s;
        box-shadow : var(--popup-shadow);
		
		@include bp(md) {
			max-width: 900px;
		}
	}

	&-close {
		cursor: pointer;
		transition: color 0.3s;
		position: absolute;
		top: 16px;
		right: 16px;
		padding: 7px;
		z-index: 300;

		&:hover {
			color: $color-grey;
		}
	}
	
	&-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: scale(1.8);
		transition-duration: 0.5s;
	}

	&-content {
		// POPUP WITH IMAGE
		&-left {
			overflow: hidden;

			@include bp(xs, lg) {
				max-height: 300px;
			}
		}

		&-right {
			position: relative;

			@include bp(xs, lg) {
				margin-top: 20px;
			}

			.page-title--secondary {
				@include bp(lg) {
					margin-top: 0;
				}
			}
		}
	}


	&-header {
		padding: 20px 40px;

		#{$prefix} &-title {
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;
            padding-right: 15px;
		}

	}

	&-form{
		overflow: hidden;
        min-height: 110px;
	}

	&-step {
		opacity: 0;
		
		&:not(.active) {
			display: none;
		}
	}
}