
/****************** ELEVATORS *******************/

:root{
    --toggle-size: 20px; //size of the toggle icon
}
$elevator: 'data-elevator';

[#{$elevator}]{
    overflow: hidden;
    transition: 0.5s;
    &:not(.active){
        max-height:0;
    }
}

[#{$elevator}--mobile]{
    @include bp(md){
        overflow: initial;
        &:not(.active){
            max-height:none;
        }
    }
}

[#{$elevator}-toggle]{
    cursor: pointer;
}

.elevator {
    &-toggle {

        &,
        &--mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & em {
                position: relative;
                z-index: 5;
                display: block;
                font-style: normal !important;
            }

            &.active::after {
                transform: rotate(180deg);
            }
            
            &--mobile {
                @include bp(md) {
                    &::after{
                        display: none !important;
                    }
                }
            }

            &::after {
                content: '\e90f';
                font-size: var(--toggle-size);
                line-height: var(--toggle-size);
                height: var(--toggle-size);
                transition : $duration;
                margin-left: 5px;
                z-index: 6;
                font-family: $icon;
            }

            /*
            &::before {
                content: '';
                background-image: linear-gradient(180deg, rgba($color-grey-lighter, 0) 0%, $color-grey-lighter 50%);
                position: absolute;
                bottom: 0;
                left: 0;
                height: calc(100% + 85px);
                width: 100%;
                opacity: 1;
                transition : $duration;
            }
            
            
            &--gradient {
                &::before {
                    background-image: linear-gradient(180deg, rgba($color-white, 0) 0%, $color-white 50%);
                }
            }

            &.active::before {
                opacity: 0;
                transition : $duration;
            }
            */
        }

    }

    &-target {
        // max-height: 80px;
        
        @mixin elevatorTarget() {
            max-height: 0;
            overflow: hidden;
            position: relative;
            top: 1px;
            transition : max-height 0.3s, padding 0.3s, border 0s 0.3s;
            
            &:not(.active) {
                padding-top: 0;
                padding-bottom: 0;
                border: 0px solid;
            }

            &.active {
                max-height: none;
                transition : max-height 0.3s, padding 0s, border 0s;
            }
        }
        
        @include elevatorTarget();

        &--mobile {
            @include bp(xs, sm) {
                @include elevatorTarget();
            }

        }

    }

}

