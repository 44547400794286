
//
//  Colors
//  ----------------------------------------------


$color-grey-lighter: #F9F9F9;
$color-grey-lightish: #F1F5F9;
$color-grey-light: #CBD5E1;
$color-grey-medium: #f1f1f1;
$color-grey: #94A3B8;
$color-grey-dark: #71717A;
$color-grey-darker: #09090B;
$color-black: #000000;
$color-white: #ffffff;

$color-main: $color-grey-darker;

$color-red: #ff3634;
$color-green: #009E50;
$color-orange: #EC6611;
$color-cream :#E9E5E5;
$color-horaires :#E9E5E5;
$color-puce-green: #089E42;
$color-puce-orange: #D18E06;



//for category colors
$colors-universe: #d4007a, #0E90BD, #A2C621, #EC6611, #EC6611, #EC6611, #EC6611, #EC6611, #EC6611, #FE7676, #3bf491, #f4943b, #0a4069, #0e5911, #590e1e, #11423c, #c8bf70, $color-grey-lighter;

$color-primary: #FFBA2E;
$color-primary-lighter: lighten($color-primary, 10%);
$color-primary-darker: #C08306;

$color-secondary: #786855;
$color-secondary-lighter: lighten($color-secondary, 10%);
$color-secondary-darker: #695b4a;

$color-tertiary: $color-green;
$color-tertiary-darker: darken($color-tertiary, 10%);
$color-tertiary-lighter: lighten($color-tertiary, 10%);

$color-quaternary: $color-orange;
$color-quaternary-darker: darken($color-quaternary, 10%);
$color-quaternary-lighter: lighten($color-quaternary, 10%);

$color-quinary: #9b57ff;
$color-quinary-darker: darken($color-quinary, 10%);
$color-quinary-lighter: lighten($color-quinary, 10%);

$color-error: #ff6b68;
$color-error-darker: darken($color-error, 10%);
$color-error-lighter: lighten($color-error, 10%);

$color-warning: $color-orange;
$color-warning-darker: darken($color-warning, 10%);
$color-warning-lighter: lighten($color-warning, 10%);

$color-ok: $color-green;
$color-ok-darker: darken($color-ok, 10%);
$color-ok-lighter: lighten($color-ok, 10%);

$color-success: $color-ok;
$color-success-darker: $color-ok-darker;
$color-success-lighter: $color-ok-lighter;

$color-promo: $color-ok;


$colors: (
  "primary":     $color-primary,
  "primary-lighter": $color-primary-lighter,
  "primary-darker": $color-primary-darker,
  "secondary-lighter": $color-secondary-lighter,
  "secondary":   $color-secondary,
  "secondary-darker": $color-secondary-darker,
  "tertiary-lighter": $color-tertiary-lighter,
  "tertiary-darker": $color-tertiary-darker,
  "tertiary":    $color-tertiary,
  "quaternary-lighter": $color-quaternary-lighter,
  "quaternary-darker": $color-quaternary-darker,
  "quaternary":  $color-quaternary,
  "quinary-lighter": $color-quinary-lighter,
  "quinary-darker": $color-quinary-darker,
  "quinary":     $color-quinary,
  "ok":          $color-ok,
  "ok-darker":   $color-ok-darker,
  "ok-lighter":  $color-ok-lighter,
  "success":     $color-success,
  "success-darker": $color-success-darker,
  "success-lighter": $color-success-lighter,
  "warning":     $color-warning,
  "warning-darker": $color-warning-darker,
  "warning-lighter": $color-warning-lighter,
  "error":       $color-error,
  "error-darker": $color-error-darker,
  "error-lighter": $color-error-lighter,
  "grey-lighter":$color-grey-lighter,
  "grey-lightish":$color-grey-lightish,
  "grey-light":  $color-grey-light,
  "grey":        $color-grey,
  "grey-dark":   $color-grey-dark,
  "grey-darker": $color-grey-darker,
  "black":       $color-black,
  "white":       $color-white,
) !default;

$theme-colors : $colors;

$corner-radius: 4px;

//
//  Typography
//  ---------------------------------------------

$font-montserrat : 'Roboto';
$font-sans: Arial,sans-serif;
$font-primary : $font-montserrat;
$font-secondary : 'Arial';
$fp : $font-primary;
$fs : $font-secondary;
$icon : 'theme';

//
//  Breakpoints
//  ---------------------------------------------

$breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	ml: 1024px,
	lg: 1280px,
	xl: 1440px,
	xxl: 1920px,
	xxxl: 2560px
)!default;

//
//  Gutters (for spacing between elements)
//  ---------------------------------------------


$spacer: 30px !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
  'n1': $spacer * -0.25,
  'n2': $spacer * -0.5,
  'n3': $spacer * -1,
  'n4': $spacer * -2,
  'n5': $spacer * -3,
  100: 100%,
  'auto': 'auto',
) !default;
$spacer-default: 3;

//
//  Shadows (for shadow helper and boxes)
//  ---------------------------------------------

$box-shadow: 0px 20px 25px -5px #0000001A,0px 8px 10px -6px #0000001A !default;

$box-shadow-sm : 0 2px 4px rgba($color-black, .075) !default;
$box-shadow-lg : 0 16px 32px rgba($color-black, .175) !default;
$box-shadow-inset : inset 0 1px 2px rgba($color-black, .075) !default;

$box-shadows: (
  0: none,
  null: $box-shadow,
  1: $box-shadow-sm,
  sm: $box-shadow-sm,
  2: $box-shadow,
  3: $box-shadow-lg,
  lg: $box-shadow-lg,
  inset: $box-shadow-inset,
) !default;


//
//  Borders (for borders and rounded helper)
//  ---------------------------------------------

$border-color: $color-grey-light !default;
$border-radius: 4px !default;
$border-radius-sm: 2px !default;
$border-radius-lg: 10px !default;
$border-radius-pill: 30px !default;


//
//  Transition
//  ---------------------------------------------
$duration: .3s;



