@import '../base/variables';
@import '../mixins/breakpoint';

//BASE VIDE A REPRENDRE SUR UN AUTRE PROJET SI SIMILARITES

$menu: '.menu';
$menuItem: #{$menu}-item;


 #{$menu}{

}

* {
	box-sizing: border-box;
  }

  body {
	margin          : 0;
	font-family     : 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	background-color: #eee;
  }

  .burger {
	min-width: 30px;
    height: 30px;
	cursor  : pointer;
	position: relative;

		&-container{
			padding: 12px 0 12px 12px;
		}

		& span {
			background-color: $color-white;
			height          : 2px;
			position        : absolute;
			width: calc(100% - 8px);
			left: 8px;
			transition      : all 0.3s ease;
			border-radius   : 10px;
		}

		& span:first-child {
			top: 9px;
		}

		& span:nth-child(2) {
			top: 15px;
		}

		& span:last-child {
			top: 21px;
		}

		&.active{

			& span:nth-child(2) {
				opacity: 0;
			}



			& span:first-child,
			& span:last-child {
				top : 12px;
			}

			& span:first-child {
				transform: rotate(45deg);
			}

			& span:last-child {
				transform: rotate(-45deg);
			}
		}
	}

.wrapper{
	border: 0 !important;
}

.logo{
	margin: 0;
	max-width: none;
	float: none !important;
	& img{
		margin: 0 !important;
		max-width: none;
		width: auto;
		height: 80px;
		object-fit: contain;
		display: block !important;

		@include bp(lg){
			height: 74px !important;
		}
	}

	&:not(&--footer) img{
		height: 44px;
	}

}

.nav{
	&-toggle{
		display: none !important;
	}
}

.page-header{
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	border: 0 !important;
    margin-bottom: 0;
	
	&,& .wrapper{
		background: $color-black !important;
		z-index: 1000;
	}
}

.field.newsletter{
	display: none !important;
}

.header{
	padding-top: 0 !important;
	padding-bottom: 0 !important;

	.action.skip {
		display: none;
	}

	&.panel{
		background: $color-black;
	}

	&.content{
		display: none !important;
	}
	&-container{
		background: $color-black;
		padding-top: 0;
		padding-bottom: 0;
	}

	&-top{
		z-index: 1500;
		position: relative;
		background: $color-black;
		padding: 15px 0;
		@include bp(ml){
			padding: 8px 0;
		}

		&bar {
			& + .header-container {
				.header-menu {
					@include bp(xs,md){
						top: 100px;
					}
				}
			}
		}
	}

	&-menu {
		@include bp(xs,md){
			position: absolute;
			top: 25px;
			z-index: 1000;
			padding: 60px 25px 25px;
			background: $color-black;
			height: calc(100vh - 25px);

			&:not(.active){
				transform: translateY(calc(-100% - 100px));
			}

			&-help {
				font-size: 20px !important;
			}

			.icon-phone {
				&::before {
					font-size: 20px !important;
					line-height: 20px !important;
					margin-right: 16px !important;
				}
			}
		}
	}

	 &-logo{
		margin: 0;
		padding: 0;
		margin-right: auto;
	}

	 &-content{
		display: flex;
		align-items: center;
		width: 100%;
		color: $color-white;
		position: relative;
		padding: 15px 24px 20px;
		flex-wrap: wrap;
		@include bp(lg){
			padding-top: 24px;
			padding-bottom: 24px;
			flex-wrap: nowrap;
		}

		&::before{
			position: absolute;
			left: 50%;
			top: 0;
			background: #272728;
			width: 100vw;
			height: 100%;
			transform: translate(-50%,0);
			z-index: -1;
		}
	}

	&-btn{
		 &s{
			display: flex;
			align-items: center;
			justify-content: center;
		}

		 &{
			height: 43px;
			line-height: 43px;
			margin-right: 25px;
		}

	}


	&-icon{

		position: relative;

		&-label{
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-top: 2px;
			transform: translate(-50%,100%);
			color: $color-primary;
			transition: 0.5s;
			opacity: 0;
			margin-left: 8px;
			font-size: 12px;
		}

		&:hover &-label{
			opacity: 1;
		}

		&:hover::before,
		&:hover::after{
			// opacity: 0.4;
			// transition: 0s;
			color: $color-primary;
		}

		&::after,
		&::before{
			transition: 0.5s;
			font-size: 27px;
			margin-left: 13px;
			color: $color-white;
			@include bp(md){
				margin-left: 16px;
			}
		}
	}

	&-minicart{

		position: relative;
		margin-left: 0;
		margin-top: 0;
		color: $color-main;

		&-icon{
			 &::before{
				display: none;
			}
		}

		 &-counter{
			position: absolute;
			background: $color-primary;
			text-align: center;
			right: 0;
			height: 18px;
			line-height: 18px;
			border-radius: 9px;
			font-size: 12px;
			color: $color-white;
			padding: 0 3px;
			min-width:18px;
			font-weight: bold;
			transform: translateX(50%);
		}
	}

	 &-search{

		position: relative;

		width: 100%;
		order: 9999;
		margin-top: 15px;

		@include bp(lg){
			width: auto;
			order:initial;
			margin-top: 0;
		}

		&-input{
			color: $color-white;
			background: $color-tertiary;
			height: 43px;
			border-radius: 21px;
			padding: 0 50px 0 13px;
			line-height: 43px;
			border: 0;
			margin-right: 5px;
			width: 100%;
			transition:border 0.5s, opacity 0.3s, box-shadow 0.3s;

			@include bp(lg){
				width: 235px;
			}

			&:not([disabled]):focus{
			    box-shadow: 0 0 2px 1px $color-primary;
			}

			&::placeholder{
				font-size: 10px;
			}
		}

		&-submit{
			padding: 0;
			border: 0;
			font-size: 21px;
			position:absolute;
			top:50%;
			right: 17px;
			opacity: 1;
			color: $color-white;
			transform:translate(0,-50%);
			background: none;

		}
	}

}

.-drill-opened.-deep{
	margin-top: 0 !important;
}

 .brand{
	&s{
		&-row{
			// align-items: flex-start;
		}

		&-col{
			display: block;
		}

		&-widget{
			background: $color-white;
			padding: 25px;
			display: block;
			height: 100%;
			overflow: auto;

			& a{
				color: inherit !important;
			}
		}

		&-filters{
			display: flex;
			width: 100%;
			position: sticky;
			top:0;
			padding: 20px;
			background: rgba($color-primary-lighter,0.42);

			// flex-wrap: wrap;
		}


		&-letter{
			padding: 0;

			&-item{

			}
		}
	}

	&-letter{
		&-header{
			width: 100%;
			border-bottom: 2px solid $color-primary;
			padding-bottom: 1px;
			margin: 0;
			font-size: 20px;
			text-transform: uppercase;
			font-weight: bold;
		}

	}

	&-image{

		display: flex;
		align-items: center;
		width: 100%;

		&s{
			display: flex;
			align-items: center;
			width: 100%;

			&:last-child{
				margin-bottom: 0px;
			}


			&-container{
				width: 100%;
				// display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
		}
	}

	&-item{
		width: 33.333%;
		margin: 0;
		padding: 10px;
		transition: transform 0.3s;
		&:last-child{
			margin-bottom: 30px;
		}

		&:hover{
			color: $color-primary !important;
			transform: translateY(-2px);
		}

		&-name{
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
			margin-top: 10px;
			text-align: center;
		}

		&-image{
			max-width: 100%;
			margin: 0 auto;
		}

		&s{
			width: 100%;
			display: flex;
			align-items: flex-start;
			font-weight: normal;
		}
	}


}

 .letter{
	&s{

		&,&-box{
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			align-content: flex-start;
			align-items: flex-start;
		}

		&-all{
			width: 100%;
			margin-bottom: 20px;
			& em {
				color: $color-white !important;
			}
		}
	}

	&--disabled{
		opacity: 0.8;
		background: $color-grey-lighter !important;
		color: $color-grey-light !important;
	}

	&:not(&s-all){
		margin: 0;
		height: 45px;
		width: 33.333%;
		background: transparent;
		border: 0;
		font-size: 15px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: center;
		font-weight: bold;
		border-radius:1px;
		transition: 0.3s;
		&:hover{
			background: $color-grey-lighter;
		}
	}
}
