


/********** VARS **********/

:root{
    --input-height: 44px; //all input boxes height
    --input-labelsize: 14px; //all input labels font-size
    
    --input-fontsize: 14px; //all inputs font-size by default
    --input-background: #{$color-white}; //all inputs background
    --input-border: 1px; //all inputs border-width
    --input-border-color: #{$color-grey-light}; //all inputs border-color
    --input-size: 16px; //Checkbox and radio width and height by default
    --input-radius: 4px; // Linear inputs and checkboxes border-radius default
    
    --input-active-fill: #{$color-primary} ; //background of active elements (checkboxes and radios)
    --input-active-color: #{$color-white} ; //color of active elements (checkboxes and radios)
    
}






 
/********** MIXINS **********/

@mixin inputBoxes(){

    &--small{
        --input-height: 30px;
        --input-fontsize: 10px;
        --input-size: 16px;
    }
    
    &--big{
        --input-height: 60px;
        --input-fontsize: 16px;
        --input-size: 30px;
    }
    
    &--alt{
        --input-background: #{$color-grey-lighter};
        --input-border-color: transparent;
        --input-switch-color: #{$color-grey-light};
        --input-active-fill: #{$color-primary-darker} ; //background of active elements (checkboxes and radios)
        --input-active-color: #{$color-grey-lighter} ; //color of active elements (checkboxes and radios)
    }
    
    &--rounded{
        --input-radius: calc(var(--input-height) * 0.5); // Linear inputs and checkboxes border-radius default
    }

}

@mixin linearInput(){
   appearance: none;
   outline:none;
   
   height: var(--input-height);
   line-height: var(--input-height);
   font-size: var(--input-fontsize);
   font-weight: 400;
   font-family:$font-primary;
   
   z-index: 1;
   display: block;
   position: relative;
   width: 100%;
   
   border : var(--input-border) solid var(--input-border-color);
   border-radius: var(--input-radius);
   background: var(--input-background);
   box-shadow: none;
   
   transition:border 0.5s, opacity 0.3s, box-shadow 0.3s;
   
   
   &:not([disabled]):focus{
       // box-shadow: 0 0 2px 1px $color-primary inset;
       border-color: var(--input-active-fill) !important;
   }
   
   &[readonly] {
       cursor: default;
   }

   &[disabled]{
       opacity: 0.4;
   }

    
    &:not([readonly]) {

        &.validation-failed, &.error, &.mage-error {
            border-color: $color-error;
        }

        &.valid {
            border-color: $color-success;
        }
    }
    
}


@mixin inputLabelStar{
    content: " *";
    font-size: 100%;
    line-height: 0;
    position: relative;
    top: -0.2em;
}

@mixin linearInputLabel{
    color: $color-main;
    display: block;
    text-align: left;
    // text-transform: uppercase;
    margin-bottom: 8px;
    padding-top: 7px;
    font-weight: 500;
    font-size: var(--input-labelsize);
    width: 100%;
   
   & small{
       text-transform: none;
   }
   
   .required > &::after{
       @include inputLabelStar();
   }
 
}

@mixin selectInput{
    @include linearInput();
    line-height: normal;
    padding: 10px 36px 10px 20px;
    font-weight: 500;
    box-shadow: none !important;
    color: $color-grey-darker;
    
    &::-ms-expand {
        display: none;
    }

}





/********** INPUTS **********/

.input {

    
    &-label{
        @include linearInputLabel();
    }
    
    &-row{
        display: flex;
    }

    &-box {
        
        
        position: relative;
        width: 100%;
        transition: color $duration, opacity $duration;
        // margin-bottom: 10px;
        // background:$color-white;

        &.disabled{
            opacity: 0.4;
            pointer-events: none;
        }

        .search &{
            margin-bottom: 0;
            margin-top: 0;

            @include bp(xs,ml){
                &::before{
                    right: 30px;
                }
            }
        }

        & + &{
            margin-top: 16px;
        }

        &[class*="icon-"] input{
            padding-right: 45px;
            @include bp(md){
                padding-right: 55px;
            }
        }

        &::before{
            z-index: 3;
            display: block;
            position: absolute;
            right: 13px;
            bottom: calc(var(--input-height )/ 2);
            transform: translate(0,50%);
            font-size: calc(var(--input-fontsize ) * 1.666);
            line-height: 1;
        }

        &-icon{
            z-index: 3;
            display: block;
            position: absolute;
            right: 13px;
            bottom: calc(var(--input-height )/ 2);
            transform: translate(0,50%);
            font-size: calc(var(--input-fontsize ) * 1.666);
            line-height: 1;
            color: $color-grey;

            &.icon-open {
                color: black;
            }
        }
        
        @include inputBoxes();

    }

    &-text{
        #{$prefix} &{
            @include linearInput();
            padding: 0 20px;
            text-overflow:ellipsis;
            &[type=password] {
                &::-ms-reveal {
                    display: none;
                }
            }

            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px white inset;
            }
        }
        
        @at-root #{$prefix} textarea#{&}{
            padding: 13px 20px;
            line-height: 1.5;
            resize: vertical;
            min-height: 110px;
        }

    }


    #{$prefix} &-file {

        display: none;

        &-box{
            
            @include linearInput();
            
            display: flex;
            justify-content: flex-start;
            // flex-wrap: wrap;
            align-items: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }


        &-btn{

            height: var(--input-height);
            line-height: var(--input-height);
            
        }

    }
    
    &-mandatory{
        font-size: 12px;
        font-style: normal;
        color: $color-primary;
        font-weight: 500;
        font-style: italic;
        margin-bottom: 20px;
        &::before{
            content:'* ';
        }

        &.nobefore::before{
            display: none;
        }
    }

    #{$prefix} &-btn{
    
        &{
           margin-left: 0;
           border-radius: 0 var(--input-radius) var(--input-radius) 0;
           height: var(--input-height);
           line-height: var(--input-height);
           font-size: 12px;
           margin-left: calc(var(--input-radius) * -0.75);
           padding: 0 15px;
           flex-shrink: 0;
       }
         
    }

    &-date {
        @include linearInput();
        //Differs from chosen calendar
    }
}







/********** SELECTS **********/


.select {
    
    @include selectInput();

    &:is(div){
        line-height: var(--input-height);
        text-align: center;
        padding: 0 20px;
        font-weight: 500;
    }
    
    &-label{
        @include linearInputLabel();
    }

    &-box {
        
        @include inputBoxes();
        
        position: relative;
        
        & > select{
            @include selectInput();
        }

        &::before {
            position: absolute;
            pointer-events: none;
            content:'\e925';
            font-family: $icon;
            right: 20px;
            bottom: calc(var(--input-height) / 2);
            line-height: 1;
            --font-size: calc(var(--input-fontsize) * 0.6);
            font-size: var(--font-size);
            z-index: 5;
            // color: var(--input-active-fill);
            color: $color-main;
            transform: translate(0,50%);
            transition: 0.5s;
        }

    }
}





/********** RADIOS & CHECKBOXES **********/

$radio: '.radio';

#{$radio}, .checkbox {
    
    #{$prefix} &{
        opacity: 0;
        pointer-events:none;
        position: absolute;
    }
    
    &-box {
        text-align: left;
        font-size: 0;
        @include clearfix();
        display: flex;
        // margin-bottom: 15px;
        flex-wrap: wrap;
        min-height: 0;
        position: relative;
        
        --input-radius: 0px;
        --input-fontsize: 12px;
        --input-width: var(--input-size);
        
        --input-switch-size: calc(var(--input-size) * 1.3);
        --input-switch-width: calc(var(--input-switch-size) * 2);
        --input-switch-color: #{$color-grey-lighter}; //color of inactive switch button
        
        --input-lineheight: calc(var(--input-fontsize) * 16 / 14);
        --input-padding: calc((var(--input-height) - var(--input-lineheight)) * 0.5);
        
        --input-gap: 15px;

        .input-label + & {
            margin-top:15px;
        }
        
        @include inputBoxes();

        & .mage-error{
            left: calc(var(--input-width) + var(--input-gap)) !important;
            bottom : 10px !important;
        }

    }

    &-title + &-box {
        @include breakpoint (md) {
            display: inline-block;
            vertical-align: top;
        }
    }

    &-label {
        display: inline-block;
        vertical-align: top;
        position: relative;
        cursor: default;
        user-select: none;
        
        font-size: var(--input-fontsize);
        line-height: var(--input-lineheight);
        min-height: var(--input-height);
        padding-left: calc(var(--input-width) + var(--input-gap));
        padding-top: var(--input-padding);
        padding-bottom: var(--input-padding);
        
        transition:color 0.3s;
        font-weight: 500;
        color: $color-primary;
        
        margin-right: 35px;
        @include bp(md){
            margin-right: 40px;
        }
        
        [disabled] + &{
            opacity: 0.4;
        }

        &::before,
        &::after {
            position: absolute;
            font-size: calc(var(--input-size) * 0.75);
            height: var(--input-size);
            width: var(--input-width);
            top: calc((var(--input-height) - var(--input-size)) / 2 );
            left: 0;
        }

        &::before {
            content:'';
            border: var(--input-border) solid;
            background: var(--input-background);
            border-color: var(--input-border-color);
            border-radius: calc(var(--input-radius) * 0.5);
            transition: $duration;
            box-sizing: border-box;
        }

        &::after {
            content:'\e91d';
            font-family: $icon;
            background:none;
            color: var(--input-active-color);
            transform:  scale(0);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .required > & span::after{
            @include inputLabelStar();
        }



        &--switch{

            &{
                position: relative;
                padding-left: calc(var(--input-switch-width) + var(--input-gap));
            }
            
            &::before,&::after{
                height: var(--input-switch-size);
                top: calc((var(--input-height) - var(--input-switch-size)) / 2 );
            }
            
            & em::before{
                content:attr(data-ko);
            }

            &::before{
                width: var(--input-switch-width);
                border-radius:calc(var(--input-switch-size) * 0.5);
                border: var(--input-border) solid var(--input-border-color);
                background: var(--input-background);
            }

            &::after{
                content:'';
                width: var(--input-switch-size);
                transform: scale(0.77) !important ;
                background: var(--input-switch-color);
                left: 0;
                border-radius: 50%;
            }
        }

    }
    
    &.mage-error ~ &-label ,
    &.error ~ &-label ,
    &.validation-failed + &-label {

        &::before {
            border-color: $color-error;
        }
    }
    
    &.valid + &-label {

        &::before {
            border-color: $color-success;
        }
    }





    & ~ &-label {

        cursor: pointer;

        &::after {
            // opacity: 0;
            transform: scale(0);
            transition: $duration;
        }
    }



    // &-label:hover::after,
    &:checked + &-label{

        color: var(--input-active-fill);
        &::before{
            border-color: var(--input-active-fill);
            background: var(--input-active-fill);
        }

        &::after {
            opacity: 1;
            transform: scale(0.65);
            transition: $duration;
            border-color: $color-grey-darker;
        }


        &--switch{
            
            & em::before{
                content:attr(data-ok);
            }
            
            &::before{
                background: var(--input-active-fill);
            }

            &::after{
               left: calc(var(--input-switch-width) - var(--input-switch-size));
                background-color: var(--input-active-color);
            }
        }
    }

}

#{$radio} {
    
    &-label {
        
        &-title{
            font-size: 18px;
            margin-bottom: 4px;
            font-weight: bold;
            color: $color-main;
            display: block;
            
        }

        &::before,
        &::after {
            border-radius: 100%;
            box-sizing: border-box;
        }
        
        &::before{
            font-size: 0;
            opacity: 1;
            background: var(--input-background) !important;
            border-color: var(--input-border-color);
        }
        
        &::after{
            z-index: 5;
            content:'';
            background: var(--input-active-fill);
        }
        
        &:checked + &-label{
            
            &::before{
                background: var(--input-background);
            }

            &::after{
                background: var(--input-active-fill);
            }
        }
    }

}










/********** RANGE SLIDER (DIFFERS FROM USED MODULE) **********/

.price{


    &-range{


        &-min,
        &-max{
            &::after {
                content: attr(data-currency) !important;
            }
            transition: background 0.3s;
            padding: 5px;
            margin: -5px;
            &:hover{
                background:$color-grey;
                cursor: pointer;
            }
        }

        &-min{
            float: left;
        }

        &-max{
            float: right;
        }

        &-current{
            @include clearfix();
            font-size: 12px;
            font-weight: 500;
        }
    }
}















/********** QTY SELECTORS (VOIR G20 POUR SELECTEURS PLUS COMPLEXES) **********/
.qtty{
    #{$prefix} &{
        display: flex;
        align-items: center;
    }
    
    $qtySmall : #{&} +"--small";
    $qtyVertical : #{&} +"--vertical";
    
    #{$prefix} &--vertical{
        flex-direction: column-reverse;
        justify-content: center;
    }
    
    #{$prefix} &--small{
        --input-height: 30px;
        padding: 2px 14px;
        background: white;
        border-radius: calc(var(--input-height)/2 + 4px);
        border: 1px solid $color-grey-light;
    }


    &-text{
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-main;

        &::before{
            margin-right: 15px;
        }

        &::after{
            margin-left: 15px;
        }
    }

    
    &-input{
        
        #{$prefix} &{
            border-radius: 0;
            background: transparent;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            width: 55px;
            padding: 0 5px;
            height: var(--input-height);
            line-height: var(--input-height);
            border: 0;
        }
        
        #{$prefix} #{$qtySmall} &{
            font-size: 14px;
            width: calc(var(--input-height) * 1.6);
            height: var(--input-height);
            font-weight: 500;
            // pointer-events: none;

        }
        
        #{$prefix} #{$qtyVertical} &{
            width: var(--input-height);
            height: calc(var(--input-height) * 1.1);
            line-height: calc(var(--input-height) * 1.1);
        }
        
    }
    
    &-btn{
        
        #{$prefix} &{
            height: var(--input-height);
            width: var(--input-height);
            line-height: var(--input-height);
            min-width: var(--input-height);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            padding: 0;
            
            &--minus{
                &::before{
                    content:'-';
                }
            }
            
            &--plus{
                &::before{
                    content:'+';
                }
            }
            
            em{
                font-style: normal;
            }
        }
        
        #{$prefix} #{$qtySmall} &{
            font-size: 20px;
            width: var(--input-height);
            height: var(--input-height);
            min-width: var(--input-height);
            border-width: 1px;
            border-radius: 50%;
            background: $color-white;

            & > em{
                color: $color-main;
                font-weight: 400;
            }
        }
        
        
    }
}




/********** OTHERS **********/


.helper{
    cursor: pointer;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}






/********** OVERRIDE MAGENTO **********/

input[type=text]::-ms-clear { display: none; }

.requiredfields{
    font-weight: 700;
    text-align: right;
    font-size: 10px;
}

.field-error,
.error,
.message.warning,
.mage-error{
    
    &:not(input):not(select):not(.message){
        max-width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;    
        font-size: 10px;
        color: $color-error;
        font-style: italic;
    }
}

div.mage-error{
    order: 10;
}

.password-strength-meter{
    font-size: 11px;
    padding-top: 3px;
}

.login {
    &-wrapper {
        @include bp(ml) {
            position: relative;
            min-height: 100vh;
            display: flex;
            overflow: hidden;
        }
    }

    &-image {
        &-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }
    }

    &-button {
        flex: 1;
        min-width: calc(50% - 4px);
        
        &s {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
    }
}