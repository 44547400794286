.step{	
	display: flex;
	align-items: center;
	font-weight: 500;
	transition: $duration;
	position: relative;
	width: 100%;
	margin-bottom: 0;
	position: absolute;
	opacity: 0;
	pointer-events: none;
	
	@include bp(md){
		display: flex;
		font-size: 20px;
		margin-top: -10px;
		width: auto;
		pointer-events: initial;
		position: relative;
		opacity: 1;
	}

	&s{
		counter-reset: number 0;
		display: flex;
		width: calc(100% + 20px);
		background: $color-primary;
		padding: 20px;
		color: $color-white;
		margin-left: -10px;
		margin-top: -20px;
		margin-bottom: 20px;
		
		
		&::after{
			content: ' /' attr(data-count);
			font-size: 16px;
			font-weight: bold;
			margin-left: 1px;
			
			@include bp(md){
				content: none;
			}
		}
		
		@include bp(md){
			padding: 0;
			background: none;
			justify-content: center;
			margin-bottom: 60px;
			margin-top: 40px;
			margin-left: 0;
			width: 100%;
			color: $color-main;
		}
	}

	&-content{
		
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		
		@include bp(md){
			justify-content: center;
			flex-direction:column;
		}
		
		&::before{
			counter-increment: number 1;
			content: counter(number);
			margin-left: auto;
			font-size: 16px;
			font-weight: bold;
			
			@include bp(md){
				font-size: 20px;
				margin-left: 0;
				height: 40px;
				width: 40px;
				border-radius:50%;
				transition: $duration;
				line-height: 40px;
				text-align: center;
				margin-bottom: 7px;
				font-family: $icon;
				content:'\e913';
				color: $color-white;
				background: $color-primary;
				font-weight: 400;
			}
		}


		&:hover {
			text-decoration: none;
		}
	}
	
	& + &{
		&::after{
			
			@include bp(md){
				content:'';
				height: 2px;
				width: 75px;
				order:-1;
				background:$color-secondary;
				margin: 0 15px;
				position: relative;
				top: -5px;
			}
			
			@include bp(lg){
				width: 115px;			
			}
		}
	}
		
	&--active{
		position: relative;
		pointer-events: initial;
		opacity: 1;
		display: flex;
	}
		

	&--active &-content::before{
		@include bp(md){
			content: counter(number);
			color: $color-white;
			background:$color-primary;
			font-family: $fp;
			font-weight: 700;
		}
	}
	
	&--active ~ & &-content::before{
		@include bp(md){
			content: counter(number);
			background: $color-grey-lighter;
			background: $color-grey-lighter;
			color: $color-main;
			font-family: $fp;
			font-weight: 700;
		}
		
	}
	
	&-text{
		display: block;	
		font-size: 14px;
		order: -1;
		
		@include bp(md){
			position: absolute;
			bottom: 0;
			left:50%;
			transform:translate(-50%,100%);
			text-align: center;
			font-size: 15px;
		}
		
		@include bp(lg){
			font-size: 20px;
		}
	}	
}