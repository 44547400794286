.btt{
    background-color: $color-primary;
    color: $color-main;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: fixed;
    bottom: 65px;
    right: 20px;
    display: block;
    cursor: pointer;
    font-size: 20px;
    z-index: 1000;
    transition: .5s ease;
    opacity: 0;

    & em::before{
        position: absolute;
        top: 0;
        left: 0;
        line-height: 60px;
        width: 100%;
        text-align: center;
        display: block;
        font-size: 18px;
        transform: rotate(-90deg);
    }

    &:hover{
        background-color: $color-primary-lighter;
    }
    &.active{
        opacity: 1;
        transition: opacity 1s;
    }
}
