// Buttons
@use 'sass:math';


$btn : '.btn';


#{$prefix} #{$btn} {


	/********************************************** BUTTONS LAYOUT **********************************************/

	&s {

		margin-top: 25px;
		font-size: 0;
		width: 100%;
		display: flex;

		&--end{
			justify-content: flex-end;
		}

		&--line {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&--full {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}

		& #{$btn}:not([style*="display: none"]) + #{$btn}{
			margin-left: 15px;
			@include bp(md){
				margin-left: 20px;
			}
		}

		@mixin btns-break(){
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&.full, &.fill{
				& > #{$btn} {
					width: 100%;

				}
			}

			#{$btn} + #{$btn} {
				margin-left: 0 !important;
				margin-right: 0 !important;
				margin-top: 16px;
				// display: inline-block;
			}

		}

		&--break{

			&{
				@include btns-break();
			}

			&--mobile{
				@include bp(xs,sm){
					@include btns-break();
				}
			}

			&--tablet{
				@include bp(xs,md){
					@include btns-break();
				}
			}

			&--tabletl{
				@include bp(xs,ml){
					@include btns-break();
				}
			}
		}

		&--gap #{$btn} + #{$btn}{
			@include bp(md){
				margin-left: 60px;
			}
		}


		&--wrap{

            flex-wrap: wrap;
            margin-right: -12px;
            margin-bottom: -10px;

			&--nomobile{
				@include bp(md){
					flex-wrap: wrap;
					margin-bottom: -10px;
                    margin-right: -10px;
				}
			}
		}

		&--wrap--nomobile #{$btn},
		&--wrap #{$btn}{
			margin-left: 0 !important;
			margin-right: 12px;
			margin-bottom: 10px;
		}

		&--group {
			display: inline-flex;
			vertical-align: middle;
			width: fit-content;

			& > #{$btn}:not([style*="display: none"]) + #{$btn} {
				margin-left: 0;
			}



			& > #{$btn}:last-child,
            & > #{$btn}:not(:first-child):not(:last-child) {
                border-left: none;
            }

            & > #{$btn}:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

			& > #{$btn}:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			& > #{$btn}.btn--icon {
				height: 100%;
				padding: 5px;
			}
		}

		&[data-btn-disabled] {
			position: relative;

			&::before {
				content: attr(data-btn-disabled);
				font-size: 14px;
				background: $color-grey-darker;
				color: $color-white;
				border-radius: 4px;
				position: absolute;
				left: 50%;
				top: 50%;
				padding: 6px 8px;
				transform: translate(-50%, -50%);
				max-width: 100%;
				width: fit-content;
				z-index: 10;
				line-height: 1;
				opacity: 0;
				pointer-events: none;
				transition: opacity .3s ease;
			}

			&:hover {
				&::before {
					opacity: 1;
				}
			}
		}
	}


	/********************************************** / END BUTTONS LAYOUT **********************************************/







	/********************************************** BUTTON APPEARANCE **********************************************/



    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        opacity: 0.5;
        cursor: default;
        pointer-events: none; // Disabling of clicks
    }

	@mixin btn-after ($color-background-hover) {
		@include bp(md) {
			&::after {
				content: '';
				position: absolute;
				top: 100%;
				left: 20%;
				width: calc(800% + 8px);
				height: 150%; // why?
				padding-top: calc(800% + 8px);
				// border-radius: 100%;
				opacity: 0;
				z-index: -1;
				-webkit-backface-visibility: hidden;
				outline: 1px solid transparent;
				transform : translate(-32%,0) rotate(-17deg) translate3d( 0, 0, 0);
				transition: all .5s ease;
				background: $color-background-hover;
			}

			&:hover::after,
			&:active::after {
				opacity: 1;
				top: 0;
				transform: translate(-50%,0) rotate(0) translate3d( 0, 0, 0);
			}
		}
	}


	@mixin btn($color-background : $color-primary, $color-text : $color-white, $color-background-hover : lighten($color-background, 10%), $color-text-hover : $color-white) {
		//Reset
		appearance: none;
		text-decoration: none;
		user-select: none;
		cursor: pointer;
		position: relative;
		z-index: 1;

		//layout
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		white-space: nowrap;
		max-width: 100%;
		overflow: hidden;


		//Sizing
		height: 36px;
		line-height: 37px;
		padding: 0 15px;

		//Appearance
		color: inherit;
		font-size: 14px;
		font-family: $fp;
		font-weight: 500;
		border: 0;
		border-radius: $corner-radius;
		color: $color-background;
		background: $color-background;
		box-shadow: 0 5px 9px transparent; //fix bug left-cropped button

		//animation
		transition: opacity 0.3s, top 0.6s, transform 0.8s;

		&:focus {
			outline: 0;
		}

        $iconSize : math.div(25,15)*0.5em;
        --toggle-size: $iconSize;

		& > em, & > span {
			z-index: 15;
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			text-align: center;
			justify-content: center;
			white-space: nowrap;
			color: $color-text;
			transition: color 0.5s;
			// font-weight: normal;

			&::before, &::after {
				font-size: $iconSize;
				line-height: math.div(25,15)*1;
				vertical-align: middle;
			}

			&::before{
				margin-right: 10px;
			}

			&::after{
				margin-left: 10px;
			}

		}

		&:hover, &:active{

			& > em, & > span {
				color: $color-text-hover;
			}
		}

		@include btn-after($color-background-hover);

	}


	@mixin btn-alt($color-text : currentColor,$color-text-hover : $color-white, $color-icon: $color-primary) {

		background: transparent;

		&::after{
			background: currentColor;
		}

		& > em, & > span {
			color: $color-text;

			&::before, &::after{
				color: $color-icon;
			}
		}

		&.active,&:hover,&:active{

			& > em, & > span {
				&::before {
					color: $color-text-hover;
				}

				color: $color-text-hover;
			}
		}

		&::before {
			content: '';
			border-radius: 4px;
			width: calc(100% - 2px);
			height: calc(100% - 2px);
			border: 1px solid currentColor;
			position: absolute;
			left: 0;
			top: 0;
			pointer-events: none;
			opacity: .6;
		}

		@include btn-after(currentColor);

	}


	&{
		@include btn();
	}

	&--primary{
		@include btn($color-primary,$color-grey-darker,$color-primary-lighter,$color-grey-darker);

		@include bp(xs, sm) {
			&:active {
				background: #ffe8ba;
			}
		}

		@include bp(md) {
			&:active:after {
				background: #ffe8ba;
			}
		}
	}

	&--secondary {
	 	@include btn($color-grey-light,$color-white,$color-grey-darker,$color-white);

		@include bp(xs, sm) {
			&:active {
				background: #ffffff;
			}
		}

		@include bp(md) {
			&:active:after {
				background: #ffffff;
			}
		}
	}

	&--tertiary {
	 	@include btn($color-grey-darker,$color-white,$color-grey-light,$color-grey-darker);
		border: 1px solid $color-grey-light;

		@include bp(xs, sm) {
			&:active {
				background: #e8eef5;
			}
		}

		@include bp(md) {
			&:active:after {
				background: #e8eef5;
			}
		}
	}

	&--quaternary {
		@include btn($color-grey-light,$color-main);
	}

	&--quinary {
		@include btn($color-quinary,$color-white);
	}

    &--white {
        @include btn($color-grey-lighter,$color-main,$color-grey-lighter,$color-main);
    }

	&--alt{
		@include btn-alt($color-white, $color-main);
	}

	&--empty{
		@include btn-alt($color-main,$color-main,inherit);
	}

	@mixin btn-big() {
		font-size: 18px;
		padding: 0 32px;
		height: 60px;
		border-radius:4px;
		line-height: 60px;
		$iconSize : math.div(25,15)*1em;

		& em{
			display: flex;

			&::before,&::after{
				font-size: $iconSize;
			}

			&::after{
				margin-left: auto;
				font-size: 16px;
				padding-left: 16px;

				@include bp(md) {
					font-size: 18px;
					padding-left: 30px;
				}
			}
		}

		&--alt em::after{
			margin-left: -10px;
		}
	}

	@mixin btn-medium() {
		font-size: 16px;
		padding: 0 24px;
		height: 48px;
		border-radius: 4px;
		line-height: 48px;
	}

	//small variant for buttons
	&--small{
		font-size: 14px;
		height: 36px;
		line-height: initial;
		padding: 0 20px;
		border-radius: 18px;
		font-weight: normal;
	}

	// big variant for buttons
	&--big{
		@include btn-big();
	}

	&--medium {
		@include btn-medium();
	}

	&--variable{
		@include bp(mxs,md){
			@include btn-big();
		}
	}

	//icon-only button
	&--icon {
		width: 40px;
		padding: 0 20px;
		height: 40px;

		& em::before {
			font-size: 18px;
			margin-right: 0;
		}
	}

	// tag button
	&--tag {
		padding: 5px;
		font-size: 10px;
		border-radius: 4px;
		line-height: initial;
		font-weight: normal;
		height: auto;

		& + #{$btn} {
			margin-left: 10px !important;
		}

		em::before {
			margin-right: 5px;
			font-size: 10px;
			line-height: 0;
		}
	}

	&--static {
		pointer-events: none;
	}

	&--sso {
		height: 48px;

		span {
			width: auto !important;
			font-size: 16px;
		}
	}



	/********************************************** / END BUTTON APPEARANCE **********************************************/


}

.modal-popup {
    & .action-primary {
        @extend .btn--primary;
        display: inline-block !important;
    }
    & .action-secondary {
        @extend .btn--secondary;
        display: inline-block !important;
    }
}
