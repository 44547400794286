@use 'sass:math';


*{
	// background: red !important;
}


html,
input,
textarea,
select,
	{
	// font: normal normal 14px/1 $font-primary;
	// color: $color-main;
}

html{
	overflow-x:hidden;
}

#{$prefix} {
	line-height: 1.4;
	color: $color-white !important;
	// overflow-x: hidden;
    text-rendering: geometricprecision;
	background: $color-grey-darker;
	font-family: $fp;
	font-size: 14px;

	@include bp(md) {
		height: auto;
	}

	&.nav-open,
	&.sidebar-open,
	&.popup-open {
		overflow: hidden;
	}

	& {
		scrollbar-gutter: stable both-edges;
	}

	//theming internal scrollbars
	& ::-webkit-scrollbar{-webkit-appearance:none;width:8px;height:10px}
	& ::-webkit-scrollbar-track{background:rgba($color-black,.1);border-radius:10px}
	& ::-webkit-scrollbar-thumb{cursor:pointer;border-radius:5px;background:rgba($color-black,.25);transition: .2s ease}
	& ::-webkit-scrollbar-thumb:window-inactive{background:rgba($color-black,.15)}
	& ::-webkit-scrollbar-thumb:hover{background:rgba(128,135,139,.8)}
}



#{$prefix} * {
	box-sizing: border-box;
	outline: none !important;
	font-family: $fp;
}



/*********** ELEMENTS GENERAL STYLES ************/

a{
	color: inherit;
	text-decoration: none;
	outline: none;
	text-decoration:none;
	border: 0;

	&:active {
		transition: 0s;
	}

	&:hover{
		color: inherit;
		text-decoration: none;
	}
}

p{
	color: inherit;
}

.stroke {
	text-decoration: line-through;
}

.strong{
	font-weight: 700;
}

.small {
	font-size: (math.div(12, 14) * 1em);
}

.big{
	font-size: (math.div(24, 18) * 1em);
}

.full {
	width: 100%!important;
}

.fill {
	@include bp(xs,md) {
		width: 100%!important;
	}
	@include bp(ml) {
		width: auto !important;
	}
}

.center {
	display: flex;
	justify-content: center;
	width: 100%;

	&--vertical{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.img{
    &-cover{
        width: 100%;
        height: 100%;
        object-fit: cover;

        &--abs{
			@include bp(md){
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
			}
        }

		&--bg{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			z-index: -1px;
		}
    }

	&-illu{
		height: 312px;
	}
}

.price{
    &,&-including-tax,&-excluding-tax{
        font-size: inherit !important;
    }
}

[class*=border-]{
    border-style: solid;
}


/****************** LINKS *******************/

@mixin link {
  color: inherit;
  transition: 0.3s;
  cursor: pointer;
  width: fit-content;
  font-weight: 500;
}

@mixin link-underline {
  border-bottom: 1px solid;
  font-weight: 500;
}

#{$prefix} .link {

	&s{
		margin: 20px auto;
	}

	@include link;

	&::before, &::after{
		font-size: 18px;
		line-height: 18px;
	}

	@include bp(xs,md){
		&::before{
			margin-right: 18px;
		}

		&::after{
			margin-left: 18px;
		}
	}

	&:active,
	&:hover {
		color: $color-primary !important;
		text-decoration: none;
        transition: 0s;
	}

	& em{
		font-size: 18px;
		@include bp(ml){
			font-size: 0;
		}
	}

	&--variable{
		@include bp(xs,md){
			font-size: 18px;
			line-height: 28px;
		}
	}

	&--emphasis{
		&::before,&::after{
			color: $color-primary;
		}
	}

	&--hover{
		@include link;
		position: relative;

		&::before{
			content:'';
			width: 0;
			background: currentColor;
			height: 1px;
			left: 0;
			bottom: -1px;
			position: absolute;
			transition: 0.3s;
		}

		&:hover::before{
			width: 100%;
		}
	}

	&-wrapper {
		cursor: pointer;

		$iconSize : 1.5em;
        --toggle-size: $iconSize;

		& > em {
			position: relative;
			display: flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			white-space: nowrap;
			font-style: italic;

			&::before, &::after {
				font-size: $iconSize;
				vertical-align: middle;
			}

			&::before  {
				margin-right: 10px
			}

			&::after  {
				margin-left: 10px
			}
		}

	}

	&--underline {
		@include link;
		@include link-underline;
	}

	&--selected{
		display: inline-block;
		border-bottom: 2px solid $color-primary;
	}

	&--important {
		@include link;
		border-bottom: 1px solid;
		font-weight: 700;
	}

	&--revert {
		@include link;
		color: $color-primary-darker;

		&:hover,
		&:active {
			color: $color-primary !important;
		}
	}

	&--normalize{
		@include link;
		font-size: 14px;
	}

	&--active {
		@include link;
		color: $color-primary !important;
	}

	&--alt {
		@include link;
		color: $color-grey-light;
		font-weight: bold;
	}

	&--small {
		@include link;
		font-size: 0.85em;
	}
}




/****************** TITLES AND DESCRIPTION *******************/


@mixin first-and-last{
	&--first{
		margin-top: 0 !important;
	}

	&--last{
		margin-bottom: 0 !important;
	}

    &--alone{
        margin-top: 0;
        margin-bottom: 0;
    }
}

$primary: 'primary';
$secondary: 'secondary';
$tributary: 'tributary';
$tertiary: 'tertiary';
$quaternary: 'quaternary';
$quinary: 'quinary';


@mixin page-title($level,$base: null) {

	$gap: 0;
	#{$prefix} &{

    	padding: 0;

    	@if $level == $primary {
    		$gap: 16px;
    		position: relative;
    		font-family: $fp;
    		font-weight: 900;
    		font-size: 48px;
    		line-height: 1;

			@include bp(ml){
				font-size: 60px;
			}
    	}

    	@if $level == $secondary {
    		$gap: 16px;
    		font-size: 36px;
    		font-weight: 800;
    		line-height: 50px;

			@include bp(ml){
				font-size: 48px;
			}

    	}

		@if $level == $tributary {
    		$gap: 16px;
    		font-family: $fp;
    		font-size: 30px;
    		font-weight: 800;
    		line-height: 36px;
			display: flex;
			align-items: center;

			&::before{
				font-size: 56px;
				margin-right: 30px;
				color: $color-primary;
			}

			@include bp(ml){
				font-size: 36px;
				line-height: 40px;
			}
    	}


    	@if $level == $tertiary {
    		$gap: 16px;
    		position: relative;
    		font-family: $fp;
    		font-size: 18px;
    		font-weight: 700;
    		line-height: 28px;
			display: flex;
			align-items: center;

			&::before{
				font-size: 24px;
				margin-right: 15px;
				color: $color-primary;
			}


    	}

    	@if $level == $quaternary {
    		$gap: 20px;
    		font-size: 16px;
    		font-weight: 500;
    	}

    	@if $level == $quinary {
    		$gap: 20px;
    		font-size: 14px;
    		font-weight: 500;
            line-height: 1.3;
    	}

    	margin-top: $gap;
    	margin-bottom: $gap;

        &:only-child{
            margin-bottom: 0;
        }


		&--subtitled{
			position: relative;
			&::after{
				content: '';
				position: absolute;
				bottom: $gap * -1;
				left: 0;
				height: 2px;
				width: 25%;
				background: $color-primary;
			}
		}

    }

	@if $base != null {
        @each $item in $base {
    		#{$prefix} & + #{$item} {
    			margin-top: $gap * -0.8;
    		}

    		@if length($base) < 2 {
        		#{$prefix} &#{$item+'--gap'}{
        			margin-top: $gap;
        		}
            }
    	}
    }
}


.page {

	&-main{
		padding: 0 !important;
		max-width: none !important;
	}

	&-title {
		$title: &;

		&-wrapper{
			padding: 18px 0;

			@include bp(ml){
				padding: 15px 0;
			}
		}

		@include bp(xs, sm) {
			br {
				display: none;
			}
		}

		@include first-and-last();

		&--#{$primary}{
			@include  page-title($primary,[$title]);
		}

		&--#{$secondary}{
			@include  page-title($secondary,[$title]);
		}

		&--#{$tributary}{
			@include  page-title($tributary,[$title]);
		}

		&--#{$tertiary}{
			@include  page-title($tertiary,[$title]);
		}

		&--#{$quaternary}{
			@include  page-title($quaternary,[$title]);
		}

		&--#{$quinary}{
			@include  page-title($quinary,[$title]);
		}
	}


	&-description{
		@include first-and-last();
		color: $color-main;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 30px;

		@include bp(md){
			font-size: 15px;
			line-height: 26px;
		}
	}

}




/****************** JS HELPERS *******************/



[data-click],
[data-focus],
[data-toggle] {
	cursor: pointer;
}

[data-atc] {
	cursor: pointer;
}


[data-lazy] {
	opacity: 0;
	height: 0;
}

/****************** PAGE LAYOUT *******************/

.full {
	width: 100%;

	&--mobile {
		@include bp(xs, sm) {
			width: 100% !important;

			&.row {
				width: calc(100% + 30px) !important;
			}
		}
	}
}


.col-main{
	margin-top: 0;
}


/////// CONTAINERS ///////

.container {
	display: block;
	margin: 0 auto;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	max-width:100%;

	@include bp(md){
		padding-left: 24px;
		padding-right: 24px;
	}

	@include bp(lg){
		padding-left: 0;
		padding-right: 0;
	}

	&--nogap{
		padding: 0 !important;
	}

	&::before {
		display: none;
	}

	& & {
		width: auto !important;
		padding: 0;
	}

	@include bp(sm) {
		// width: 576px;
	}

	@include bp(md) {
		width: 768px;
	}

	@include bp(ml) {
		width: 1024px;
	}

	@include bp(lg) {
		width: 1220px;
	}

	@include bp(xl) {
		width: 1312px;
	}

	& + & {
		margin-top: 30px;
	}

	&--verysmall {
		max-width: 768px;
	}

	&--small {
		max-width: 1090px;
	}

	&--medium {
		max-width: 1280px;
	}

	&--big{
		width: 100%;
		max-width: 1980px;
		margin: 0 auto !important;

		@include bp(ml){
			padding-left: 40px;
			padding-right: 40px;
        }
	}
}




/////// ALTERNATE RIGHT LEFT ///////


.ipoint {

    &s {
        width: 100%;
		display: flex;
		flex-direction: column;

		& > *{

			@include bp(xs,sm){
				flex-direction: column-reverse !important;
			}

		}
    }

    & {
        align-items: stretch;
        text-align: left;
		margin: 0;
		border-radius : 0;
		overflow: hidden;

		&--radius{
			border-radius : 12px;
		}

        & > *{
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: relative;
			padding-left: 0;
			padding-right: 0;
        }
    }

    &:nth-child(even){
        flex-direction: row-reverse;
    }

    &s--revert{
        & > *{



            flex-direction: row-reverse;

            &:nth-child(even){
                flex-direction: row;
            }

        }
    }

    & + & {
		margin-top: 15px;

        @include bp(md) {
            margin-top: 30px;
        }
    }

}




/////// TEXT SIZES ///////

@mixin text-size($level) {

    line-height: 1.70;
    letter-spacing: normal;

    @if $level == $primary {
        font-size: 18px;
        @include bp(md){
            font-size: 16px;
        }
    }

    @if $level == $secondary {
        font-size: 15px;
        @include bp(md){
            font-size: 14px;
        }
    }

    @if $level == $tertiary {
        font-size: 14px;
    }

    @if $level == $tributary {
        font-size: 12px;
    }

    @if $level == $quaternary {
        font-size: 10px;
    }

    @if $level == $quinary {
        font-size: 8px;
    }

}


.text {

    &--primary{
        @include text-size($primary);
    }

    &--secondary{
        @include text-size($secondary);
    }

    &--tertiary{
        @include text-size($tertiary);
    }

    &--tributary{
        @include text-size($tributary);
    }

    &--quaternary{
        @include text-size($quaternary);
    }

    &--quinary{
        @include text-size($quinary);
    }

}

/****************** BREADCRUMBS *******************/

.breadcrumbs {


	&-item {
		&s {
			display: flex;
			width: 100%;
			align-items: center;
			font-size: 12px;
			padding: 15px 0;
		}

		& a {
			transition : $duration;

			&:hover {
				color: $color-primary;
			}
		}


		& + & {
			&::before {
				padding: 0 10px;
				content: '>';
			}
		}
	}

	&-link {
		display: flex;
		align-items: center;

		&::after {
			content: ' > ';
			color: $color-secondary;
			padding: 0 8px;
		}
	}
}


/****************** ARRANGE ORDER OF ELEMENTS *******************/


@mixin orders(){
	&-begin {
		order: -1;
	}

	&-1 {
		order: 1;
	}

	&-2 {
		order: 2;
	}

	&-3 {
		order: 3;
	}

	&-4 {
		order: 4;
	}

	&-5 {
		order: 5;
	}

	&-6 {
		order: 6;
	}

	&-end {
		order: 99999;
	}

}

.order {

	@include orders();

	&-mobile {
		@include bp(xs, sm) {
			@include orders();
		}
	}

	&-md {
		@include bp(md) {
			@include orders();
		}
	}

	&-ml {
		@include bp(ml) {
			@include orders();
		}
	}

	&-lg {
		@include bp(lg) {
			@include orders();
		}
	}

	&-xl {
		@include bp(xl) {
			@include orders();
		}
	}

	&-xxl {
		@include bp(xxl) {
			@include orders();
		}
	}
}

.ok {
	color: $color-primary;
}

.ko {
	color: $color-error;
}



/****************** LOADER *******************/



@keyframes rotating {
	from {
		transform : translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform : translate(-50%, -50%) rotate(360deg);
	}
}

@keyframes rotatingY {
	0% {
		transform : translate(-50%, -50%) rotateY(0deg);
	}

	25% {
		transform : translate(-50%, -50%) rotateY(90deg);
	}

	25.0001% {
		transform : translate(-50%, -50%) rotateY(90deg);
	}

	74.9999% {
		transform : translate(-50%, -50%) rotateY(270deg);
	}

	75% {
		transform : translate(-50%, -50%) rotateY(270deg);
	}

	100% {
		transform : translate(-50%, -50%) rotateY(360deg);
	}
}

.loader {

    position: relative;

    &--full{
        position:fixed;
        top:50% !important;
        left:50% !important;
        transform: translate(-50%, -50%) !important;
        // background:$color-white;
        z-index: 9999;
        width: 100vw;
        height: 100vh;
    }

	&::before {
        content: '';
		background: $color-white;
		top: 0;
		left: 0;
        height: 100%;
        width: 100%;
		transition: 0.5s;
		z-index: 99999;
		opacity: 0;
		pointer-events: none;
    	position:absolute;
    	// background:$color-white;
    	z-index: 9999;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		// background:$color-white;
		border: 3px dashed $color-primary;
        transition: 0.5s;
		animation: rotating 4s linear infinite;
        z-index: 99999;
        opacity: 0;
        pointer-events: none;
	}

    &.loading::before {
		opacity: 0.7;
		pointer-events: all;
	}


    &.loading::after{
        opacity: 1;
        pointer-events: all;
    }


    /*

	& img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: none !important;
	}

	& p,
	&-logo {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: $color-main;
		font-size: 0;
		pointer-events:none;

		&::before,
		&::after {
			font-family: $icon;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}


		&::before {
			animation : rotatingY 3.5s linear infinite;
			content: '\e912';
			font-size: 50px;
		}
	}
    */

}



/****************** STATUS *******************/



.status{

	font-weight: bold;
	&::before{
	    content:'•';
	    display: inline-block;
	    margin-right: 3px;
	}

    &--ok{
        color: $color-ok;
    }

    &--warning{
    	color: $color-warning;
    }

    &--error{
    	color: $color-error;
    }
}

.bg{
	&-image{
		background-color: $color-black;
		background-size: cover;
		background-position: top center;
		background-image: url(../images/footer-bg-light.webp);
	}

	&-grey-medium {
		background: $color-grey-medium;
	}
}


.bar{
    background: $color-black;
    color: $color-white;
    padding-top: 7px;
    padding-bottom: 7px;
	border-bottom: 1px solid $color-grey-lighter;
	width: 100vw !important;
    margin-left: 50%;
    transform: translateX(-50%);
}

.page-header .header.panel{
	max-width: none !important;
	padding: 0 !important;
}

.nowrap{
	white-space: nowrap;

	&--desktop{
		@include bp(ml){
			white-space: nowrap;
		}
	}
}



.schedule{

	position: relative;


	&-step{

		&::before{
			content: '';
			height: 16px;
			width: 16px;
			background: $color-primary;
			border-radius: 50%;
			border: 4px solid $color-main;
			box-sizing: border-box;
			left: 0;
			position: absolute;
			transform: translate(-50%,calc(22px - 50%));
		}

		&[data-step]{
			&::before{
				content: attr(data-step);
				border: 0;
				background: $color-primary;
				height: 30px;
				width: 30px;
				line-height: 30px;
				font-weight: 500;
				font-size: 12px;
				text-align: center;
				cursor: pointer;
				transition: 0.3s transform;
			}

			&:hover::before{
				transform: translate(-50%,calc(22px - 50%)) scale(1.2);
			}
		}
	}

	&::before{
		content: '';
		height: calc(100% - 185px);
		top: 85px;
		left: 0;
		background: $color-primary;
		width: 4px;
		position: absolute;
		transform: translateX(-50%);
	}

	&-map{
		height: 745px;
		width: 100%;
		border-radius: 8px;
		position: sticky !important;
		top: 80px;


		& .mapboxgl-ctrl{
			&-zoom{
				&-in,&-out{
					position: absolute;
					top: 14px;
					height: 60px;
					width: 60px;
					background: $color-primary;
					border-radius: 4px !important;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: 0.3s background;

					&:hover{
						background: rgba($color-primary,0.5) !important;
					}
					&::before{
						font-weight: 100;
						font-size: 30px;
					}
				}

				&-out{
					right: 90px;
					&::before{
						content:'―';
					}
				}

				&-in{
					right: 14px;
					&::before{
						content:'+';
					}
				}
			}

			&-icon{
				display: none !important;
			}

			&-compass{
				display: none;
			}

		}
	}

	&-marker{
		background: $color-primary;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 12px;
		transition: 0.3s opacity;

		&--zero {
			&::before {
				content: 'D' !important;
			}
		}

		&:hover{
			opacity: 0.7 !important;
		}

		&::before{
			content:attr(data-step);
		}

		&[data-step="0"],
		&[data-step="1"],
		&[data-step="2"]{
			position: absolute;
			z-index: 1;
		}
	}

	@include bp(xs, sm) {
		.select {
			--input-height: 36px;
		}
	}
}

.index{
	&--top{
		z-index: 100;
	}

	&--bottom{
		z-index: -1;
	}
}

.nav-sections{
	display: none !important;
}

.column.main{
	padding-bottom: 0 !important;
}

.hero{
	&-box {
		padding: 24px 32px;
		border-radius: 8px;

		.input {
			&-text {
				--input-height: 52px;
				padding: 0 20px;

				.icon-users span {
					transition: color .3s ease;
				}

				&:hover {
					.icon-users span {
						color: $color-primary-darker;
					}
				}
			}
		}
	}

	&-time{
		padding: 12px 13px;
		font-weight: 500;
		height: 28px;
		max-height: 28px;
		color: $color-grey-dark;
		border-radius: 14px;
		display: flex;
		align-items: center;
		line-height: 1;
		position: absolute;
		top: 24px;
		right: 32px;
		border: 1px solid;

		&::before{
			margin-right: 5px;
		}

		span {
			line-height: 1;
		}
	}

	&-close{
		z-index: 30;
		color: $color-grey-dark;
		font-size: 15px;
		position: absolute;
		top: 0;
		right: 0;
		padding: 30px;
		line-height: 1;
		transition: 0.5s;
		&:hover{
			color: $color-primary;
		}
	}
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: #b0b0b0 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #ffffff;
}

*::-webkit-scrollbar-thumb {
	background-color: #b0b0b0;
	border-radius: 10px;
	border: 3px solid #ffffff;
}

.go{
	&--next{
		position: relative;
		&::before{
			font-weight: 400;
			position: absolute;
			font-size: 8px;
			right: 15px;
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);

		}
	}
}


.loading {
	position: relative;
	overflow: hidden;

	&::before, &::after {
		content: '';
		display: block;
		position: absolute;
	}

	&::before {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background: rgba(255, 255, 255, 0.7);
		z-index: 10;
	}

	&::after {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 30px;
		height: 30px;
		border: 2px solid $color-primary;
		border-bottom: 0;
		border-left: 0;
		z-index: 12;
		border-radius: 100%;
		animation: loading 1s linear infinite;
	}

	@keyframes loading {
		0% {
			transform: translate(-50%, -50%) rotate(0deg);
		}
		100% {
			transform: translate(-50%, -50%) rotate(360deg);
		}
	}
}

.section {
	&-overview {
		&:first-of-type {
			@include bp(xs, md) {
				padding-top: 18px !important;
			}
		}
	}
}

.modal {
    &-content {
        color: $color-main !important;
    }
}
