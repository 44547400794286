@mixin slideIn {
    opacity: 0;
    transform: translateY(-20px);
    transition: .5s ease;
}

@keyframes slideIn {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@mixin scaleIn {
    opacity: 0;
    transform: scale(1.2);
    transition: .5s ease;
}

@keyframes scaleIn {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@mixin appearChild {
    &,&--child{
        transition: 0.3s;
    }
}


.appear{
    $appear : #{&};
    
    
    &--scale{
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0;
            transform: scale(1.25);
        }
    }
    
    &--bottom{
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0;
            transform:translateY(50px);
        }
    }
    
    &--top{
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0;
            transform:translateY(-50px);
        }
    }
    
    &--right{
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0;
            transform:translateX(50px);
        }
    }
    
    &--left{
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0;
            transform:translateX(-50px);
        }
    }
    
    &--rotate{
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0 !important;
            transform: scale(0) rotate(100deg) !important;
        }
    }
    
    &--shutter{
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0 !important;
            transform: rotateX(90deg) !important;
        }
    }

    &--fade {
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0;
        }
    }

    &--shrink {
        @include appearChild();
        
        #{$appear}:not(.active) &--child,
        &:not(.active){
            opacity: 0;
            transform: scaleX(0.1) !important;
        }
    }
    
    &--delay{
        @for $i from 1 through 20 {
            &:nth-child(#{$i}){
                transition-delay: #{($i - 1)* 0.15s} !important;
            }
        }
    }
}
